import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  animate, state, style, transition, trigger,
} from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';
import { Buffer } from 'buffer';
import { StorageService } from 'src/app/common/services/storage.service';
import { AuthService } from 'src/app/common/services/auth.service';
import { environment } from 'src/environments/environment';
import { AdminService } from 'src/app/services/admin/admin.service';
import { SignUpModel } from './models/signup.model';
import { SignInModel } from './models/signin.model';
import { ModalModel } from '../pre-registration/models/modal.model';
import { AuthResponseModel } from 'src/app/common/models/auth-response.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('active', [
      state('initial', style({
        opacity: '0%',
      })),
      state('final', style({
        opacity: '100%',
      })),
      transition('initial <=> final', animate('300ms ease-in')),
    ]),
    trigger('modal', [
      state('initial', style({
        opacity: '0%',
      })),
      state('final', style({
        opacity: '100%',
      })),
      transition('initial <=> final', animate('100ms ease-in')),
    ]),
  ],
})
export class LoginComponent implements OnInit {
  public username: string = '';
  public recaptcha: string = '';
  public toggleScreen : boolean = false;
  public titleSignIn : string = 'Bem vindo ao Portal OPL';
  public titleSignUp : string = 'Cadastrar';
  public titleLostPass : string = 'Reset de Senha';
  public sending: boolean = false;
  public modalModel = new ModalModel();
  public lostPassword : boolean = false;
  public image: string = '';
  public isLoading: boolean = true;

  constructor(
    private authService: AuthService,
    private storageService: StorageService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private adminService: AdminService,
  ) { }

  ngOnInit(): void {
    this.storageService.clear();
    this.authService.loginCredentials().subscribe({
      next: () => {
        this.getCoverImage();
      },
    });
    const route = this.activatedRoute.snapshot.routeConfig?.path;
    if (route?.includes('login/reset-password')) {
      this.lostPassword = true;
    }
  }

  onSignIn(event: SignInModel) {
    this.username = event.user ?? '';
    const password = event.password ?? '';
    this.recaptcha = event.recaptcha ?? '';

    if (this.username.length === 0 || password.length === 0 || this.recaptcha.length ===0 || this.sending) return;

    this.sending = true;
    this.authService.loginPassword(this.username, password).subscribe({
      next: this.handleLoginSuccess.bind(this),
      error: this.handleLoginError.bind(this),
    });
  }

  handleLoginSuccess(res: AuthResponseModel) {
    this.authService.checkIfUserCanLogin(this.username, this.recaptcha).subscribe({
      next: (role) => {
        this.storageService.setLoggedUserData(role);
        this.storageService.setTokenPassword(res.access_token);
        this.storageService.setScope(res.scope);
        this.storageService.setUser(this.username);

        this.sending = false;
        this.router.navigate(['solicitation']);
      },
      error: (error) => {
        this.sending = false;
        this.storageService.clear();
        this.handleLoginError(error);
      },
    });
  }

  handleLoginError(res: HttpErrorResponse) {
    const message = JSON.parse(JSON.stringify(res.error));
    this.sending = false;
    this.modalModel.imagePath = '../../../assets/icons/attention-600.gif';
    this.modalModel.title = 'Não foi possivel realizar o login';
    this.modalModel.description = message?.Message ?? 'Login e/ou senha incorreta.';
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.success = false;
    this.modalModel.showModal = true;
  }

  onLostPassword() {
    this.router.navigate(['/login/reset-password']);
  }

  onGoSignUp() {
    this.toggleScreen = !this.toggleScreen;
  }

  onBack() {
    this.toggleScreen = !this.toggleScreen;
  }

  onSignUp(event: SignUpModel) {
    if (
      event.cnpj?.length !== 14
      || event.password?.length === 0
      || !event.passIsValid
      || event.recaptcha?.length === 0
    ) return;

    this.sending = true;

    const token = this.storageService.getTokenCredentials();
    if (!token) {
      this.authService.loginCredentials().subscribe({
        next: () => { this.navigate.bind(this, event); },
        error: this.handleloginCredentialsError.bind(this),
      });
    }
    this.navigate(event);
  }

  navigate(event: SignUpModel) {
    this.sending = false;

    const str = JSON.stringify(event);
    const data = Buffer.from(str).toString('base64');

    this.router.navigate(['/pre-register', data]);
  }

  handleloginCredentialsError() {
    this.sending = false;
    this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
    this.modalModel.title = 'Não foi possível iniciar o cadastro';
    this.modalModel.description = 'Tente novamente mais tarde ou entre em contato com algum administrador.';
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.success = false;
    this.modalModel.showModal = true;
  }

  onGCertifica() {
    window.open(environment.GCertificaUrl, '_blank');
  }

  closeModal() {
    this.modalModel.showModal = false;
  }

  getCoverImage() {
    this.isLoading = true;
    this.adminService.getCoverImagesPublic().subscribe({
      next: (res) => {
        this.image = res.content;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
        this.image = '../../../assets/images/Scania-Raizen.jpg';
      },
    });
  }
}
