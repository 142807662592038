export const environment = {
  production: false,
  local: false,
  LocalUrl: 'https://localhost:5001/api/',
  LocalUrlPims: 'https://localhost:44344/api/',
  AppName: 'POPL',
  ClientIdFront: '33b49d34-e91b-4b86-b4ac-ed61946f0180',
  ClientSecretFront: '9a0f6264-9aee-472c-bc57-11617981643c',
  ClientIdPublicFront: 'e0dd5067-5bce-4848-9ec9-473450a1ca82',
  ClientSecretPublicFront: '9dda6085-ccb8-415e-9854-e6060eb724b0',
  SensediaBaseUrl: 'https://wapi.minhati.com.br/',
  RecaptchaSiteKey: '6LcUfXIqAAAAAF4l9HsPiB7FdiLUtaPfb3U1L8el',
  GCertificaUrl: 'https://raizen.gcertifica.com.br/homologacao-fornecedor',
  LGPDTerm: 'https://bit.ly/3JFPOYA',
};
