import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Buffer } from 'buffer';
import { OperatorService } from 'src/app/services/operator/operator.service';
import { CreateOperatorModel } from 'src/app/services/operator/models/create-operator-model';
import { GetOperatorModel } from 'src/app/services/operator/models/get-operator-model';
import { ModalModel } from './models/modal.model';
import { SignUpModel } from '../login/models/signup.model';

@Component({
  selector: 'app-pre-registration',
  templateUrl: './pre-registration.component.html',
  styleUrls: ['./pre-registration.component.scss'],
})
export class PreRegistrationComponent implements OnInit {
  public companyForm = this.formBuilder.group({
    cnpj: ['', Validators.required],
    corporateName: ['', Validators.required],
    tradingName: ['', Validators.required],
    numberRegistrationState: [''],
    numberRegistrationMunicipal: [''],
    companySize: ['', Validators.required],
    employeesCount: ['', Validators.required],
    validatedFantasy: ['', Validators.required],
    validatedCompany: ['', Validators.required],
  });

  public addressForm = this.formBuilder.group({
    address: ['', Validators.required],
    number: ['', Validators.required],
    complement: [''],
    neighborhood: ['', Validators.required],
    cep: ['', Validators.required],
    uf: ['', Validators.required],
    city: ['', Validators.required],
  });

  public contactForm = this.formBuilder.group({
    name: ['', Validators.required],
    email: [''],
    phone: ['', Validators.required],
    recaptcha: ['', Validators.required],
    validatedEmail: ['', Validators.required],
  });

  public showModal = false;
  public modalText = '';
  public modalModel = new ModalModel();
  public loading = false;
  private password = '';

  constructor(
    public formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute:ActivatedRoute,
    private operatorService: OperatorService,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params : ParamMap) => {
      try {
        const data = params.get('data') ?? '';
        const str = Buffer.from(data, 'base64').toString();
        const model = JSON.parse(str) as SignUpModel;

        this.companyForm.controls.cnpj.patchValue(model.cnpj);
        this.password = Buffer.from(model.password ?? '').toString('base64');
        this.modalText = `Notificaremos você no email ${this.contactForm.controls.email} assim que analisarmos suas informações`;
      } catch (error: any) {
        this.router.navigate(['/login']);
      }
    });
  }

  submit() {
    this.loading = true;

    const recaptcha = this.contactForm.controls.recaptcha.value ?? '';
    const request = {
      operatorId: this.companyForm.controls.cnpj.value ?? '',
      companyName: this.companyForm.controls.corporateName.value ?? '',
      fantasyName: this.companyForm.controls.tradingName.value ?? '',
      municipalRegistration: this.companyForm.controls.numberRegistrationMunicipal.value ?? '',
      stateRegistration: this.companyForm.controls.numberRegistrationState.value ?? '',
      employeeNumberName: this.companyForm.controls.employeesCount.value ?? '',
      companySizeName: this.companyForm.controls.companySize.value ?? '',
      street: this.addressForm.controls.address.value ?? '',
      number: this.addressForm.controls.number.value ?? '',
      complement: this.addressForm.controls.complement.value ?? '',
      district: this.addressForm.controls.neighborhood.value ?? '',
      zipCode: this.addressForm.controls.cep.value ?? '',
      city: this.addressForm.controls.city.value ?? '',
      state: this.addressForm.controls.uf.value ?? '',
      contactName: this.contactForm.controls.name.value ?? '',
      email: this.contactForm.controls.email.value ?? '',
      phone: this.contactForm.controls.phone.value ?? '',
      password: this.password,
    } as CreateOperatorModel;

    this.operatorService.createOperatorPublic(request, recaptcha).subscribe({
      next: this.handleOperatorSuccess.bind(this),
      error: this.handleOperatorError.bind(this),
    });
  }

  handleOperatorSuccess(res: GetOperatorModel) {
    this.loading = false;
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.description = `Notificaremos você no email ${res.email} assim que analisarmos suas informações`;
    this.modalModel.imagePath = '../../../assets/icons/approve-modal.svg';
    this.modalModel.title = 'Seus dados foram enviados com sucesso!';
    this.modalModel.success = true;
    this.showModal = true;
  }

  handleOperatorError(res: HttpErrorResponse) {
    const message = JSON.parse(JSON.stringify(res.error));
    this.loading = false;
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.description = message?.Message;
    this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
    this.modalModel.title = 'Não foi possível enviar seu cadastro!';
    this.modalModel.success = false;
    this.showModal = true;
  }

  clickModal() {
    this.showModal = false;
    if (this.modalModel.success) {
      this.router.navigate(['/login']);
    }
  }
}
