import { ModalModel } from 'src/app/pages/pre-registration/models/modal.model';
import {
  Component, Input, Output, EventEmitter,
} from '@angular/core';
import { Router } from '@angular/router';
import { Utils } from 'src/app/common/utils/utils';
import { GetOperatorModel } from 'src/app/services/operator/models/get-operator-model';
import { OperatorService } from '../../../../../services/operator/operator.service';
import { CryptoService } from 'src/app/services/Encryption/CryptoService';

@Component({
  selector: 'app-preregister-operator-list-item',
  templateUrl: './preregister-operator-list-item.component.html',
  styleUrls: ['./preregister-operator-list-item.component.scss'],
})
export class PreRegisterOperatorListItemComponent {
  @Input() preRegisterList!: GetOperatorModel[];
  @Output() operatorRegister = new EventEmitter<GetOperatorModel>();

  public modalModel: ModalModel = new ModalModel();

  public loadingAccept: boolean[] = [];
  public loadingReject: boolean[] = [];

  constructor(
    private router: Router,
    private operatorService: OperatorService,
    public util: Utils,
    private cryptoService: CryptoService,
  ) {}

  approve(event: any, id: number, operatorId: string) {
    event.stopPropagation();
    this.loadingAccept[id] = true;
    this.operatorService.updateOperatorApprove(operatorId).subscribe({
      next: this.requestSuccess.bind(this),
      error: () => this.requestError(operatorId),
    });
  }

  requestSuccess(res: GetOperatorModel) {
    this.handleSuccess(
      'Aprovação confirmada',
      'Aprovação realizada com sucesso',
    );
    this.removeFromGrid(res.operatorId);
    this.operatorRegister.emit(res);
  }

  requestError(operatorId: string) {
    this.handleErrors(
      operatorId,
      'Erro ao processar',
      'Ocorreu um erro ao aceitar a empresa',
    );
  }

  disapprove(event: any, id: number, operatorId: string) {
    event.stopPropagation();
    this.loadingReject[id] = true;
    this.operatorService.updateOperatorDisapprove(operatorId).subscribe({
      next: () => this.requestSuccessDelete(operatorId),
      error: () => this.requestErrorDelete(operatorId),
    });
  }

  requestSuccessDelete(operatorId: string) {
    this.removeFromGrid(operatorId);
    this.handleSuccess('Deleção confirmada', 'Deleção realizada com sucesso');
  }

  requestErrorDelete(operatorId: string) {
    this.handleErrors(
      operatorId,
      'Error ao processar',
      'Ocorreu um erro ao recusar a empresa',
    );
  }

  removeFromGrid(id: string) {
    const index = this.preRegisterList.findIndex((x) => x.operatorId === id);
    this.loadingReject.splice(index, 1);
    this.loadingAccept.splice(index, 1);
    this.preRegisterList.splice(index, 1);
  }

  handleErrors(id: string, title: string, description: string) {
    const index = this.preRegisterList.findIndex((x) => x.operatorId === id);
    this.loadingReject[index] = false;
    this.loadingAccept[index] = false;
    this.showModal(title, description, false);
  }

  handleSuccess(title: string, description: string) {
    this.showModal(title, description, true);
  }

  handleDataClick(id: string) {
    var idCrypto = this.cryptoService.crypt(id);
    this.router.navigate(['/admin/operator/', idCrypto]);
  }

  showModal(title: string, description: string, success: boolean) {
    if (success) {
      this.modalModel.imagePath = '../../../assets/icons/approve-modal.svg';
    } else {
      this.modalModel.imagePath = '../../../assets/icons/attention-600.gif';
    }
    this.modalModel.title = title;
    this.modalModel.description = description;
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.success = false;
    this.modalModel.showModal = true;
  }

  closeModal() {
    this.modalModel.showModal = false;
  }
}
