<div class="page">
  <app-card
    title="Cadastro #{{ transporterId }}"
    icon="../../../../assets/icons/solicitation-primary-600.svg"
    [accordion]="false"
    [paddingHorizontal]="true"
  >
    <div class="container" body>
      <div class="control-container">
        <app-toogle
          [checked]="formTransp.controls.active.value ?? false"
          [label]="'Ativo'"
          (check)="onCheck()"
        ></app-toogle>
      </div>
      <div class="input-container">
        <app-input
          [label]="'CD TRANPS'"
          [control]="formTransp.controls.cd_transp"
          [disabled]="true"
        ></app-input>
        <app-input
          [label]="'CNPJ'"
          [control]="formTransp.controls.cnpj"
          [disabled]="true"
        ></app-input>
      </div>
      <div class="desc">
        <app-input
          [label]="'Descrição'"
          [control]="formTransp.controls.description"
          [disabled]="true"
        ></app-input>
      </div>
      <div class="buttons-container">
        <app-secondary-button
          [label]="'VOLTAR'"
          [enabled]="true"
          [icon]="'assets/icons/arrow-previous-primary-600.svg'"
          (clickButton)="return()"
        ></app-secondary-button>
        <app-primary-button
          [label]="'DELETAR'"
          [icon]="'assets/icons/trash-slate100.svg'"
          [color]="eButtonsColor.warning"
          [loading]="deleteLoading"
          [enabled]="!deleteLoading"
          (clickButton)="onDelete()"
        ></app-primary-button>
        <app-primary-button
          [label]="'SALVAR'"
          [loading]="updateLoading"
          [enabled]="!updateLoading"
          (clickButton)="onSave()"
        ></app-primary-button>
      </div>
    </div>
  </app-card>
</div>
<app-modal-confirmation
  [showModal]="showModal"
  [title]="'Deletar registro'"
  [description]="'Tem certeza que deseja apagar o registro?'"
  confirmLabel="Confirmar"
  confirmIcon="assets/icons/reprove-common-100.svg"
  [confirmColor]="eButtonsColor.warning"
  (cancelFunc)="denyDelete()"
  (confirmFunc)="confirmDelete()"
></app-modal-confirmation>
<app-modal
  [showModal]="modalModel.showModal"
  [title]="modalModel.title"
  [description]="modalModel.description"
  [buttonLabel]="modalModel.buttonLabel"
  [imagePath]="modalModel.imagePath"
  (clickButton)="clickModal()"
></app-modal>
