import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Utils } from 'src/app/common/utils/utils';
import { CryptoService } from 'src/app/services/Encryption/CryptoService';
import { GetCompanyGroupedPimsModel } from 'src/app/services/operator/models/get-company-grouped-pims.model';
import { OperatorService } from 'src/app/services/operator/operator.service';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';

@Component({
  selector: 'app-operator-pims',
  templateUrl: './operator-pims.component.html',
  styleUrls: ['./operator-pims.component.scss'],
})
export class OperatorPimsComponent implements OnInit {
  public id: string = '';
  public companiesPims: GetCompanyGroupedPimsModel = new GetCompanyGroupedPimsModel();
  public get ButtonColorEnum() { return ButtonColorEnum; }

  constructor(
    private service: OperatorService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public util: Utils,
    public cryptoService: CryptoService,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get('id') ?? '';
      if (this.id) {
        this.id = this.cryptoService.decrypt(this.id)
        this.getCompaniesPims();
      }
    });
  }

  getCompaniesPims() {
    this.service.getCompaniesPims(this.id).subscribe({
      next: (result) => {
        this.companiesPims = result;
      },
      error: () => {
        this.companiesPims = new GetCompanyGroupedPimsModel();
      },
    });
  }

  addOperatorPims() {
    var idCrypto = this.cryptoService.crypt(this.id);
    this.router.navigate([`admin/operator/${idCrypto}/pims/new`]);
  }
}
