import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './common/guards/auth-guard.service';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { AdminComponent } from './pages/admin/admin.component';
import { AnalystNewComponent } from './pages/admin/analyst/analyst-new/analyst-new.component';
import { AnalystComponent } from './pages/admin/analyst/analyst.component';
import { CategoryNewComponent } from './pages/admin/category/category-new/category-new.component';
import { CategoryComponent } from './pages/admin/category/category.component';
import { CoverImageComponent } from './pages/admin/config/cover-image/cover-image.component';
import { ValueLimitComponent } from './pages/admin/config/value-limit/value-limit.component';
import { OperationNewComponent } from './pages/admin/operation/operation-new/operation-new.component';
import { OperationUpdateComponent } from './pages/admin/operation/operation-update/operation-update.component';
import { OperationComponent } from './pages/admin/operation/operation.component';
import { OperatorDataComponent } from './pages/admin/operator/operator-data/operator-data.component';
import { OperatorPimsRegisterComponent } from './pages/admin/operator/operator-data/operator-pims-register/operator-pims-register.component';
import { OperatorPimsUpdateComponent } from './pages/admin/operator/operator-data/operator-pims/operator-pims-update/operator-pims-update.component';
import { OperatorComponent } from './pages/admin/operator/operator.component';
import { SubcategoryNewComponent } from './pages/admin/subcategory/subcategory-new/subcategory-new.component';
import { SubcategoryComponent } from './pages/admin/subcategory/subcategory.component';
import { UnitCategoryNewComponent } from './pages/admin/unit/unit-new/unit-category-new/unit-category-new.component';
import { UnitNewComponent } from './pages/admin/unit/unit-new/unit-new.component';
import { UnitComponent } from './pages/admin/unit/unit.component';
import { DfdvComponent } from './pages/dfdv/dfdv.component';
import { FixedCostsComponent } from './pages/fixed-costs/fixed-costs.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { PreRegistrationComponent } from './pages/pre-registration/pre-registration.component';
import { SolicitationDataComponent } from './pages/solicitation/solicitation-data/solicitation-data.component';
import { SolicitationComponent } from './pages/solicitation/solicitation.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuardService],
  },
  // #region Without Breadcrumb
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'login/reset-password',
    component: LoginComponent,
  },
  {
    path: 'login/reset-password/:token',
    component: LoginComponent,
  },
  {
    path: 'pre-register/:data',
    component: PreRegistrationComponent,
  },
  {
    path: 'pre-register',
    component: PreRegistrationComponent,
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent,
    canActivate: [AuthGuardService],
  },
  // #endregion
  // #region With Breadcrumb
  {
    path: 'solicitation',
    data: {
      breadcrumb: 'Solicitações',
      icon: 'solicitation-icon',
    },
    children: [
      {
        path: '',
        component: SolicitationComponent,
        canActivate: [AuthGuardService],
        data: { breadcrumb: '' },
      },
      {
        path: 'new',
        component: SolicitationDataComponent,
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Nova Solicitação', icon: null },
      },
      {
        path: ':id',
        component: SolicitationDataComponent,
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Solicitação #:id', icon: null },
      },
    ],
  },
  {
    path: 'fixed-costs',
    data: {
      breadcrumb: 'Lançamento Fixo',
      icon: 'fixedcost-icon',
    },
    children: [
      {
        path: '',
        component: FixedCostsComponent,
        canActivate: [AuthGuardService],
        data: { breadcrumb: '' },
      },
    ],
  },
  {
    path: 'admin',
    data: {
      breadcrumb: 'Admin',
      icon: 'admin-icon',
    },
    children: [
      {
        path: '',
        component: AdminComponent,
        canActivate: [AuthGuardService],
        data: { breadcrumb: null, icon: null },
      },
      {
        path: 'unit',
        data: { breadcrumb: 'Unidades', icon: null },
        children: [
          {
            path: '',
            component: UnitComponent,
            canActivate: [AuthGuardService],
            data: { breadcrumb: '' },
          },
          {
            path: 'new',
            component: UnitNewComponent,
            canActivate: [AuthGuardService],
            data: { breadcrumb: 'Cadastro de nova unidade' },
          },
          {
            path: ':id',
            data: { breadcrumb: 'Unidade #:id' },
            children: [
              {
                path: '',
                component: UnitNewComponent,
                canActivate: [AuthGuardService],
                data: { breadcrumb: '' },
              },
              {
                path: 'category',
                data: { breadcrumb: null },
                children: [
                  {
                    path: 'new',
                    component: UnitCategoryNewComponent,
                    canActivate: [AuthGuardService],
                    data: { breadcrumb: 'Categoria' },
                  },
                  {
                    path: ':categoryId',
                    component: UnitCategoryNewComponent,
                    canActivate: [AuthGuardService],
                    data: { breadcrumb: 'Categoria #:id' },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'operator',
        data: { breadcrumb: 'Empresas', icon: null },
        children: [
          {
            path: '',
            component: OperatorComponent,
            canActivate: [AuthGuardService],
            data: { breadcrumb: null },
          },
          {
            path: ':id',
            data: { breadcrumb: ':cnpj' },
            children: [
              {
                path: '',
                component: OperatorDataComponent,
                canActivate: [AuthGuardService],
                data: { breadcrumb: '' },
              },
              {
                path: 'pims',
                data: { breadcrumb: null },
                children: [
                  {
                    path: 'new',
                    component: OperatorPimsRegisterComponent,
                    canActivate: [AuthGuardService],
                    data: { breadcrumb: 'Cadastros no PIMS' },
                  },
                  {
                    path: ':transporterId',
                    component: OperatorPimsUpdateComponent,
                    canActivate: [AuthGuardService],
                    data: { breadcrumb: 'Transportadora #:id' },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'category',
        data: { breadcrumb: 'Categorias', icon: null },
        children: [
          {
            path: '',
            component: CategoryComponent,
            canActivate: [AuthGuardService],
            data: { breadcrumb: null },
          },
          {
            path: 'new',
            component: CategoryNewComponent,
            canActivate: [AuthGuardService],
            data: { breadcrumb: 'Cadastro de nova categoria' },
          },
          {
            path: ':id',
            component: CategoryNewComponent,
            canActivate: [AuthGuardService],
            data: { breadcrumb: 'Categoria #:id' },
          },
        ],
      },
      {
        path: 'subcategory',
        data: { breadcrumb: 'Subcategorias', icon: null },
        children: [
          {
            path: '',
            component: SubcategoryComponent,
            canActivate: [AuthGuardService],
            data: { breadcrumb: null },
          },
          {
            path: 'new',
            component: SubcategoryNewComponent,
            canActivate: [AuthGuardService],
            data: { breadcrumb: 'Cadastro de nova Subcategoria' },
          },
          {
            path: ':id',
            component: SubcategoryNewComponent,
            canActivate: [AuthGuardService],
            data: { breadcrumb: 'Subcategoria  #:id' },
          },
        ],
      },
      {
        path: 'analyst',
        data: { breadcrumb: 'Usuários', icon: null },
        children: [
          {
            path: '',
            component: AnalystComponent,
            canActivate: [AuthGuardService],
            data: { breadcrumb: '' },
          },
          {
            path: 'new',
            component: AnalystNewComponent,
            canActivate: [AuthGuardService],
            data: { breadcrumb: 'Cadastro de novo Usuário' },
          },
          {
            path: ':id',
            component: AnalystNewComponent,
            canActivate: [AuthGuardService],
            data: { breadcrumb: ':cstr' },
          },
        ],
      },
      {
        path: 'operation',
        data: { breadcrumb: 'Operações', icon: null },
        children: [
          {
            path: '',
            component: OperationComponent,
            canActivate: [AuthGuardService],
            data: { breadcrumb: '' },
          },
          {
            path: 'new',
            component: OperationNewComponent,
            canActivate: [AuthGuardService],
            data: { breadcrumb: 'Procurar Operações' },
          },
          {
            path: ':id',
            component: OperationUpdateComponent,
            canActivate: [AuthGuardService],
            data: { breadcrumb: 'Operação #:id' },
          },
        ],
      },
      {
        path: 'config/value-limit',
        component: ValueLimitComponent,
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Valor Limite das Solicitações', icon: null },
      },
      {
        path: 'config/cover-image',
        component: CoverImageComponent,
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Imagem de Capa', icon: null },
      },
    ],
  },
  {
    path: 'dfdv',
    data: { breadcrumb: 'Despesas Fixas e Variáveis', icon: 'dfdv-icon' },
    children: [
      {
        path: '',
        component: DfdvComponent,
        data: { breadcrumb: null, icon: null },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
