import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CreateSolicitationModel } from 'src/app/services/solicitation/models/create-solicitation-model';
import { GetUnitModel } from 'src/app/services/unit/models/get-unit-model';
import { GetSolicitationFilterModel } from 'src/app/services/solicitation/models/get-solicitation-filter-model';
import { CategoryStorageModel } from '../../services/category/models/category-storage.model';
import { ScopeModel } from '../models/scope.model';
import { LoggedUserModel } from '../models/logged-user.model';
import { RoleModel } from '../models/role.model';
import { GetDfdvModel } from 'src/app/services/dfdv/models/getDfdv.model';
import { GetFixedCostFilterModel } from 'src/app/services/fixedCosts/models/get-fixedCostFilter.model';
import { CryptoService } from 'src/app/services/Encryption/CryptoService';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private readonly TOKEN_PASSWORD_KEY = `${environment.AppName}TP`;
  private readonly TOKEN_CREDENTIALS_KEY = `${environment.AppName}TC`;
  private readonly SCOPE_KEY = `${environment.AppName}S`;
  private readonly USER_KEY = `${environment.AppName}U`;
  private readonly USER_LOGGED_KEY = `${environment.AppName}UL`;
  private readonly USER_SESSION_ID_KEY = `${environment.AppName}US`;
  private readonly SOLICITATION_FORM_KEY = `${environment.AppName}SF`;
  private readonly UNIT_FORM_KEY = `${environment.AppName}UF`;
  private readonly CATEGORY_FORM_KEY = `${environment.AppName}CF`;
  private readonly SOLICITATION_FILTER_FORM_KEY = `${environment.AppName}SFF`;
  private readonly DFDV_KEY = `${environment.AppName}DFDV`;
  private readonly FIXEDCOST_FILTER_FORM_KEY = `${environment.AppName}FCFF`;

  constructor(private cryptoService: CryptoService) {}

  clear(): void {
    window.localStorage.clear();
    window.sessionStorage.clear();
  }

  setUserSessionId(userSessionId: string) {
    window.localStorage.removeItem(this.USER_SESSION_ID_KEY);
    window.localStorage.setItem(this.USER_SESSION_ID_KEY, userSessionId);
  }

  getTokenPassword() {
    const token = window.localStorage.getItem(this.TOKEN_PASSWORD_KEY) ?? '';
    return this.cryptoService.decrypt(token);
  }

  setTokenPassword(token: string) {
    const tokenCrypt = this.cryptoService.crypt(token);
    window.localStorage.removeItem(this.TOKEN_PASSWORD_KEY);
    window.localStorage.setItem(this.TOKEN_PASSWORD_KEY, tokenCrypt);
  }

  getTokenPasswordInitial() {
    return window.localStorage.getItem(this.TOKEN_PASSWORD_KEY) ?? '';
  }

  setTokenPasswordInitial(token: string) {
    window.localStorage.removeItem(this.TOKEN_PASSWORD_KEY);
    window.localStorage.setItem(this.TOKEN_PASSWORD_KEY, token);
  }

  getTokenCredentials() {
    return window.sessionStorage.getItem(this.TOKEN_CREDENTIALS_KEY) ?? '';
  }

  setTokenCredentials(token: string) {
    window.sessionStorage.removeItem(this.TOKEN_CREDENTIALS_KEY);
    window.sessionStorage.setItem(this.TOKEN_CREDENTIALS_KEY, token);
  }

  getScope() {
    const scope = window.localStorage.getItem(this.SCOPE_KEY) ?? '';
    const scopeStr = this.cryptoService.decrypt(scope);

    try {
      return JSON.parse(scopeStr) as ScopeModel;
    } catch (error: any) {
      return new ScopeModel();
    }
  }

  setScope(scope: ScopeModel) {
    const scopeStr = JSON.stringify(scope);
    const scopeCrypt = this.cryptoService.crypt(scopeStr);
    window.localStorage.removeItem(this.SCOPE_KEY);
    window.localStorage.setItem(this.SCOPE_KEY, scopeCrypt);
  }

  getRole() {
    const scopeModel = this.getScope();

    try {
      return scopeModel.roles[0] as RoleModel;
    } catch (error: any) {
      return new RoleModel();
    }
  }

  setUser(user: string) {
    const userCrypt = this.cryptoService.crypt(user);
    window.localStorage.removeItem(this.USER_KEY);
    window.localStorage.setItem(this.USER_KEY, userCrypt);
  }

  getUser() {
    const userCrypt = window.localStorage.getItem(this.USER_KEY) ?? '';
    return this.cryptoService.decrypt(userCrypt);
  }

  setLoggedUserData(loggedUser: LoggedUserModel) {
    this.setUserSessionId(loggedUser.userSessionId);

    const loggeruserStr = JSON.stringify(loggedUser);
    const loggedUserCrypt = this.cryptoService.crypt(loggeruserStr);
    window.localStorage.removeItem(this.USER_LOGGED_KEY);
    window.localStorage.setItem(this.USER_LOGGED_KEY, loggedUserCrypt);
  }

  getLoggedUserData() {
    const loggedUserCrypt = window.localStorage.getItem(this.USER_LOGGED_KEY) ?? '';
    const loggedUserStr = this.cryptoService.decrypt(loggedUserCrypt);

    try {
      return JSON.parse(loggedUserStr) as LoggedUserModel;
    } catch (error: any) {
      return new LoggedUserModel();
    }
  }

  getAnalystRole() {
    return 'Fake';
  }

  setCreateSolicitationForm(solicitation: CreateSolicitationModel) {
    const solicitationFormStr = JSON.stringify(solicitation);
    const solicitationFormCrypt = this.cryptoService.crypt(solicitationFormStr);
    window.sessionStorage.removeItem(this.SOLICITATION_FORM_KEY);
    window.sessionStorage.setItem(this.SOLICITATION_FORM_KEY, solicitationFormCrypt);
  }

  getCreateSolicitationForm() {
    const solicitationFormCrypt =  window.sessionStorage.getItem(this.SOLICITATION_FORM_KEY) ?? '';
    const solicitationFormStr = this.cryptoService.decrypt(solicitationFormCrypt);

    try {
      return JSON.parse(solicitationFormStr) as CreateSolicitationModel;
    } catch (error: any) {
      return undefined;
    }
  }

  clearCreateSolicitationForm() {
    window.sessionStorage.removeItem(this.SOLICITATION_FORM_KEY);
  }

  setUnit(units: GetUnitModel) {
    const unitsStr = JSON.stringify(units);
    const unitsCrypt = this.cryptoService.crypt(unitsStr);
    window.sessionStorage.removeItem(this.UNIT_FORM_KEY);
    window.sessionStorage.setItem(this.UNIT_FORM_KEY, unitsCrypt);
  }

  getUnit() {
    const unitsCrypt = window.sessionStorage.getItem(this.UNIT_FORM_KEY) ?? '';
    const unitsStr = this.cryptoService.decrypt(unitsCrypt);

    try {
      return JSON.parse(unitsStr) as GetUnitModel;
    } catch (error: any) {
      return undefined;
    }
  }

  clearUnit() {
    window.sessionStorage.removeItem(this.UNIT_FORM_KEY);
  }

  setCreateCategoryForm(category: CategoryStorageModel) {
    const categoryFormStr = JSON.stringify(category);
    const categoryFormCrypt = this.cryptoService.crypt(categoryFormStr);
    window.sessionStorage.removeItem(this.CATEGORY_FORM_KEY);
    window.sessionStorage.setItem(this.CATEGORY_FORM_KEY, categoryFormCrypt);
  }

  getCreateCategoryForm() {
    const categoryFormCrypt = window.sessionStorage.getItem(this.CATEGORY_FORM_KEY) ?? '';
    const categoryFormStr = this.cryptoService.decrypt(categoryFormCrypt);

    try {
      return JSON.parse(categoryFormStr) as CategoryStorageModel;
    } catch (error: any) {
      return undefined;
    }
  }

  clearCreateCategoryForm() {
    window.sessionStorage.removeItem(this.CATEGORY_FORM_KEY);
  }

  getSolicitationFilterForm() {
    const solicitationFilterFormCrypt = window.sessionStorage.getItem(this.SOLICITATION_FILTER_FORM_KEY) ?? '';
    const solicitationFilterStr = this.cryptoService.decrypt(solicitationFilterFormCrypt);

    try {
      return JSON.parse(solicitationFilterStr) as GetSolicitationFilterModel;
    } catch (error: any) {
      return undefined;
    }
  }

  setSolicitationFilterForm(solicitationFilter: GetSolicitationFilterModel) {
    const solicitationFilterFormStr = JSON.stringify(solicitationFilter);
    const solicitationFilterFormCrypt = this.cryptoService.crypt(solicitationFilterFormStr);
    window.sessionStorage.removeItem(this.SOLICITATION_FILTER_FORM_KEY);
    window.sessionStorage.setItem(this.SOLICITATION_FILTER_FORM_KEY, solicitationFilterFormCrypt);
  }

  clearSolicitationFilterForm() {
    window.sessionStorage.removeItem(this.SOLICITATION_FILTER_FORM_KEY);
  }

  setDfdv(dfdv: GetDfdvModel) {
    const dfdvStr = JSON.stringify(dfdv);
    const dfdvCrypt = this.cryptoService.crypt(dfdvStr);
    window.sessionStorage.removeItem(this.DFDV_KEY);
    window.sessionStorage.setItem(this.DFDV_KEY, dfdvCrypt);
  }

  getDfdv() {
    const dfdvCrypt = window.sessionStorage.getItem(this.DFDV_KEY) ?? '';
    const dfdvStr = this.cryptoService.decrypt(dfdvCrypt);

    try {
      return JSON.parse(dfdvStr) as GetDfdvModel;
    } catch (error: any) {
      return undefined;
    }
  }

  clearDfdv() {
    window.sessionStorage.removeItem(this.DFDV_KEY);
  }

  getFixedCostFilterForm() {
    const fixedCostFilterFormCrypt =
    window.sessionStorage.getItem(this.FIXEDCOST_FILTER_FORM_KEY) ?? '';
    const fixedCostFilterStr = this.cryptoService.decrypt(fixedCostFilterFormCrypt);

    try {
      return JSON.parse(fixedCostFilterStr) as GetFixedCostFilterModel;
    } catch (error: any) {
      return undefined;
    }
  }

  setFixedCostFilterForm(fixedCostFilter: GetFixedCostFilterModel) {
    const fixedCostFilterStr = JSON.stringify(fixedCostFilter);
    const fixedCostFilterFormCrypt = this.cryptoService.crypt(fixedCostFilterStr);
    window.sessionStorage.removeItem(this.FIXEDCOST_FILTER_FORM_KEY);
    window.sessionStorage.setItem(this.FIXEDCOST_FILTER_FORM_KEY, fixedCostFilterFormCrypt);
  }

  clearFixedCostFilter() {
    window.sessionStorage.removeItem(this.FIXEDCOST_FILTER_FORM_KEY);
  }
}
