<div class="content">
  <app-infobox [type]="InfoboxTypeEnum.attention">
    <div content>
      <span>É necessario se cadastrar também na plataforma</span>
      <app-buttons
        label="G-Certifica"
        icon="../../../../assets/icons/arrow-slate100.svg"
        [type]="eButtonsType.navigate"
        [enabled]="true"
        [right]="true"
        (clickButton)="onGCertifica()"
      ></app-buttons>
    </div>
  </app-infobox>
  <app-lgpd-info></app-lgpd-info>
  <form>
    <app-input
      label="Nome do Contato"
      placeholder="Informe o nome do contato"
      [type]="eInputType.text"
      errorMessage="Este campo é obrigatório"
      [control]="form.controls.name"
      [maxlength]="50"
    ></app-input>
    <div class="input-box">
      <app-input
        label="E-mail"
        placeholder="email@example.com"
        [type]="eInputType.text"
        [flatLoading]="emailLoading"
        errorMessage="{{ emailError }}"
        [control]="form.controls.email"
        [validated]="emailIsValid"
        [showSideIcon]="true"
        sideIconLabel="@"
        [sideIconType]="eSideIconType.text"
      ></app-input>
      <app-input
        label="Telefone/Celular"
        placeholder="(00) 00000-0000"
        [type]="eInputType.text"
        [mask]="eInputMask.phone"
        errorMessage="Este campo é obrigatório"
        [control]="form.controls.phone"
        [showSideIcon]="true"
        [sideIconLabel]="'../../../assets/icons/phone-common-600.svg'"
        [sideIconType]="eSideIconType.image"
      ></app-input>
    </div>
  </form>
  <div [formGroup]="form" class="captcha-container">
    <re-captcha formControlName="recaptcha"></re-captcha>
  </div>
</div>
