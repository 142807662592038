import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CommonModule, CurrencyPipe, DecimalPipe, registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  RECAPTCHA_SETTINGS,
  RecaptchaFormsModule,
  RecaptchaModule,
  RecaptchaSettings,
} from 'ng-recaptcha-2';
import { NgxCurrencyDirective } from 'ngx-currency';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CnpjPipe } from './common/pipes/cnpj.pipe';
import { PhonePipe } from './common/pipes/phone.pipe';
import { HttpInterceptorService } from './common/services/http-interceptor.service';
import { DfdvEffects } from './common/store/dfdv/dfdv.effects';
import { dfdvReducer, loadingReducer } from './common/store/dfdv/dfdv.state';
import { FixedCostEffects } from './common/store/fixedCost/fixedCost.effects';
import {
  errorFixedCostReducer,
  fixedCostReducer,
  loadingFixedCostReducer,
} from './common/store/fixedCost/fixedCost.state';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { AdminComponent } from './pages/admin/admin.component';
import { AnalystListCardComponent } from './pages/admin/analyst/analyst-list/analyst-list-card/analyst-list-card.component';
import { AnalystListItemComponent } from './pages/admin/analyst/analyst-list/analyst-list-item/analyst-list-item.component';
import { AnalystListComponent } from './pages/admin/analyst/analyst-list/analyst-list.component';
import { AnalystNewComponent } from './pages/admin/analyst/analyst-new/analyst-new.component';
import { AnalystComponent } from './pages/admin/analyst/analyst.component';
import { CategoryListItemComponent } from './pages/admin/category/category-list/category-list-item/category-list-item.component';
import { CategoryListComponent } from './pages/admin/category/category-list/category-list.component';
import { CategoryNewComponent } from './pages/admin/category/category-new/category-new.component';
import { CategoryComponent } from './pages/admin/category/category.component';
import { CoverImageComponent } from './pages/admin/config/cover-image/cover-image.component';
import { ValueLimitComponent } from './pages/admin/config/value-limit/value-limit.component';
import { OperationCardComponent } from './pages/admin/operation/operation-card/operation-card.component';
import { OperationDetailComponent } from './pages/admin/operation/operation-detail/operation-detail.component';
import { OperationNewComponent } from './pages/admin/operation/operation-new/operation-new.component';
import { OperationUpdateComponent } from './pages/admin/operation/operation-update/operation-update.component';
import { OperationComponent } from './pages/admin/operation/operation.component';
import { OperatorDataComponent } from './pages/admin/operator/operator-data/operator-data.component';
import { OperatorNewComponent } from './pages/admin/operator/operator-data/operator-new/operator-new.component';
import { OperatorPimsRegisterComponent } from './pages/admin/operator/operator-data/operator-pims-register/operator-pims-register.component';
import { OperatorPimsCardComponent } from './pages/admin/operator/operator-data/operator-pims/operator-pims-card/operator-pims-card.component';
import { OperatorPimsDetailComponent } from './pages/admin/operator/operator-data/operator-pims/operator-pims-detail/operator-pims-detail.component';
import { OperatorPimsUpdateComponent } from './pages/admin/operator/operator-data/operator-pims/operator-pims-update/operator-pims-update.component';
import { OperatorPimsComponent } from './pages/admin/operator/operator-data/operator-pims/operator-pims.component';
import { OperatorListItemComponent } from './pages/admin/operator/operator-list/operator-list-item/operator-list-item.component';
import { OperatorListComponent } from './pages/admin/operator/operator-list/operator-list.component';
import { OperatorComponent } from './pages/admin/operator/operator.component';
import { PreRegisterOperatorListItemComponent } from './pages/admin/operator/preregister-operator-list/preregister-operator-list-item/preregister-operator-list-item.component';
import { PreRegisterOperatorListComponent } from './pages/admin/operator/preregister-operator-list/preregister-operator-list.component';
import { SubcategoryListCardComponent } from './pages/admin/subcategory/subcategory-list/subcategory-list-card/subcategory-list-card.component';
import { SubcategoryListDetailComponent } from './pages/admin/subcategory/subcategory-list/subcategory-list-detail/subcategory-list-detail.component';
import { SubcategoryListComponent } from './pages/admin/subcategory/subcategory-list/subcategory-list.component';
import { SubcategoryNewComponent } from './pages/admin/subcategory/subcategory-new/subcategory-new.component';
import { SubcategoryComponent } from './pages/admin/subcategory/subcategory.component';
import { UnitListCardComponent } from './pages/admin/unit/unit-list/unit-list-card/unit-list-card.component';
import { UnitListDetailComponent } from './pages/admin/unit/unit-list/unit-list-detail/unit-list-detail.component';
import { UnitListComponent } from './pages/admin/unit/unit-list/unit-list.component';
import { UnitCategoryCardComponent } from './pages/admin/unit/unit-new/unit-category-card/unit-category-card.component';
import { UnitCategoryDetailComponent } from './pages/admin/unit/unit-new/unit-category-detail/unit-category-detail.component';
import { UnitCategoryNewComponent } from './pages/admin/unit/unit-new/unit-category-new/unit-category-new.component';
import { UnitNewComponent } from './pages/admin/unit/unit-new/unit-new.component';
import { UnitComponent } from './pages/admin/unit/unit.component';
import { DfdvCreateModalComponent } from './pages/dfdv/dfdv-create-modal/dfdv-create-modal.component';
import { DfdvFilterComponent } from './pages/dfdv/dfdv-filter/dfdv-filter.component';
import { DfdvDetailComponent } from './pages/dfdv/dfdv-list/dfdv-detail/dfdv-detail.component';
import { DfdvListComponent } from './pages/dfdv/dfdv-list/dfdv-list.component';
import { DfdvSummarizeRoutingModule } from './pages/dfdv/dfdv-route/dfdv-route-routing.module';
import { DfdvRouteComponent } from './pages/dfdv/dfdv-route/dfdv-route.component';
import { BenefitComponent } from './pages/dfdv/dfdv-sumarize/benefit/benefit.component';
import { ChargeComponent } from './pages/dfdv/dfdv-sumarize/charges/charge/charge.component';
import { ChargesComponent } from './pages/dfdv/dfdv-sumarize/charges/charges.component';
import { CumulativeIncidenceOplComponent } from './pages/dfdv/dfdv-sumarize/charges/cumulative-incidence-opl/cumulative-incidence-opl.component';
import { CumulativeIncidenceComponent } from './pages/dfdv/dfdv-sumarize/charges/cumulative-incidence/cumulative-incidence.component';
import { DfdvSummarizeComponent } from './pages/dfdv/dfdv-sumarize/dfdv-summarize.component';
import { DriverFactorComponent } from './pages/dfdv/dfdv-sumarize/driver-factor/driver-factor.component';
import { FleetModalNewConfigComponent } from './pages/dfdv/dfdv-sumarize/fleet/fleet-modal-new-config/fleet-modal-new-config.component';
import { FleetSpecificationsComponent } from './pages/dfdv/dfdv-sumarize/fleet/fleet-specifications/fleet-specifications.component';
import { FleetTableCapitalComponent } from './pages/dfdv/dfdv-sumarize/fleet/fleet-table-capital/fleet-table-capital.component';
import { FleetTableProfitComponent } from './pages/dfdv/dfdv-sumarize/fleet/fleet-table-profit/fleet-table-profit.component';
import { FleetTableTaxComponent } from './pages/dfdv/dfdv-sumarize/fleet/fleet-table-tax/fleet-table-tax.component';
import { FleetTableTractorComponent } from './pages/dfdv/dfdv-sumarize/fleet/fleet-table-tractor/fleet-table-tractor.component';
import { FleetTableTrailerComponent } from './pages/dfdv/dfdv-sumarize/fleet/fleet-table-trailer/fleet-table-trailer.component';
import { FleetComponent } from './pages/dfdv/dfdv-sumarize/fleet/fleet.component';
import { AdministrativeTeamUniformComponent } from './pages/dfdv/dfdv-sumarize/general-costs/administrative-team/administrative-team-uniform.component';
import { GeneralCostsComponent } from './pages/dfdv/dfdv-sumarize/general-costs/general-costs.component';
import { MaintenanceAndWarehouseTeamUniformComponent } from './pages/dfdv/dfdv-sumarize/general-costs/maintence-warehouse/maintence-and-warehouse-team-uniform.component';
import { OfficeExpensesComponent } from './pages/dfdv/dfdv-sumarize/general-costs/office-expenses/office-expenses.component';
import { OperationalTeamUniformComponent } from './pages/dfdv/dfdv-sumarize/general-costs/operational-team/operational-team-uniform.component';
import { OperationalTeamComponent } from './pages/dfdv/dfdv-sumarize/operational-team/operational-team.component';
import { PlantComponent } from './pages/dfdv/dfdv-sumarize/plant/plant.component';
import { ProfitComponent } from './pages/dfdv/dfdv-sumarize/profit/profit.component';
import { RvParameterComponent } from './pages/dfdv/dfdv-sumarize/rv-parameter/rv-parameter.component';
import { ScopeConsumptionComponent } from './pages/dfdv/dfdv-sumarize/scope/scope-consumption/scope-consumption.component';
import { ScopeEquipmentComponent } from './pages/dfdv/dfdv-sumarize/scope/scope-equipment/scope-equipment.component';
import { ScopeFeeComponent } from './pages/dfdv/dfdv-sumarize/scope/scope-fee/scope-fee.component';
import { ScopeRentalComponent } from './pages/dfdv/dfdv-sumarize/scope/scope-rental/scope-rental.component';
import { ScopeComponent } from './pages/dfdv/dfdv-sumarize/scope/scope.component';
import { SumarizeHeaderComponent } from './pages/dfdv/dfdv-sumarize/sumarize-header/sumarize-header.component';
import { SumarizeInfoComponent } from './pages/dfdv/dfdv-sumarize/sumarize-info/sumarize-info.component';
import { SupportFleetComponent } from './pages/dfdv/dfdv-sumarize/support-team/support-fleet/support-fleet.component';
import { SupportTeamCardComponent } from './pages/dfdv/dfdv-sumarize/support-team/support-team-card/support-team-card.component';
import { SupportTeamTotalComponent } from './pages/dfdv/dfdv-sumarize/support-team/support-team-total/support-team-total.component';
import { SupportTeamComponent } from './pages/dfdv/dfdv-sumarize/support-team/support-team.component';
import { TableComponent } from './pages/dfdv/dfdv-sumarize/table/table.component';
import { TaxComponent } from './pages/dfdv/dfdv-sumarize/tax/tax.component';
import { ConsumptionComponent } from './pages/dfdv/dfdv-sumarize/variable-costs/consumption/consumption.component';
import { MaintenanceComponent } from './pages/dfdv/dfdv-sumarize/variable-costs/maintenance/maintenance.component';
import { TiresComponent } from './pages/dfdv/dfdv-sumarize/variable-costs/tires/tires.component';
import { VariableCostsFleetComponent } from './pages/dfdv/dfdv-sumarize/variable-costs/variable-costs-fleet/variable-costs-fleet.component';
import { VariableCostsComponent } from './pages/dfdv/dfdv-sumarize/variable-costs/variable-costs.component';
import { DfdvComponent } from './pages/dfdv/dfdv.component';
import { FixedCostHistoryComponent } from './pages/fixed-costs/fixed-cost-unit/fixed-cost-history/fixed-cost-history.component';
import { FixedCostUnitComponent } from './pages/fixed-costs/fixed-cost-unit/fixed-cost-unit.component';
import { FixedCostsReleasesComponent } from './pages/fixed-costs/fixed-cost-unit/fixed-costs-releases/fixed-costs-releases.component';
import { FixedCostsCategoryComponent } from './pages/fixed-costs/fixed-costs-category/fixed-costs-category.component';
import { FixedCostsContentComponent } from './pages/fixed-costs/fixed-costs-content/fixed-costs-content.component';
import { FixedCostsFilterComponent } from './pages/fixed-costs/fixed-costs-filter/fixed-costs-filter.component';
import { FixedCostsHeaderComponent } from './pages/fixed-costs/fixed-costs-header/fixed-costs-header.component';
import { FixedCostsRoutingModule } from './pages/fixed-costs/fixed-costs-header/fixed-costs-route-routing.module';
import { FixedCostsListComponent } from './pages/fixed-costs/fixed-costs-list/fixed-costs-list.component';
import { FixedCostsComponent } from './pages/fixed-costs/fixed-costs.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { LostPasswordComponent } from './pages/login/lost-password/lost-password.component';
import { SignInComponent } from './pages/login/sign-in/sign-in.component';
import { SignUpComponent } from './pages/login/sign-up/sign-up.component';
import { AddressStepComponent } from './pages/pre-registration/address-step/address-step.component';
import { CompanyStepComponent } from './pages/pre-registration/company-step/company-step.component';
import { ContactStepComponent } from './pages/pre-registration/contact-step/contact-step.component';
import { LgpdInfoComponent } from './pages/pre-registration/lgpd-info/lgpd-info.component';
import { PreRegistrationComponent } from './pages/pre-registration/pre-registration.component';
import { SolicitationApprovalModalComponent } from './pages/solicitation/solicitation-approval-modal/solicitation-approval-modal.component';
import { SolicitationApprovementComponent } from './pages/solicitation/solicitation-data/solicitation-approvement/solicitation-approvement.component';
import { SolicitationDataComponent } from './pages/solicitation/solicitation-data/solicitation-data.component';
import { SolicitationHistoryComponent } from './pages/solicitation/solicitation-data/solicitation-history/solicitation-history.component';
import { SolicitationNewComponent } from './pages/solicitation/solicitation-data/solicitation-new/solicitation-new.component';
import { SolicitationFilterComponent } from './pages/solicitation/solicitation-filter/solicitation-filter.component';
import { SolicitationDetailComponent } from './pages/solicitation/solicitation-list/solicitation-detail/solicitation-detail.component';
import { SolicitationListComponent } from './pages/solicitation/solicitation-list/solicitation-list.component';
import { SolicitationComponent } from './pages/solicitation/solicitation.component';
import { AttachComponent } from './shared/attach/attach.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { ButtonsComponent } from './shared/buttons/buttons.component';
import { IconButtonComponent } from './shared/buttons/icon-button/icon-button.component';
import { NavigateButtonComponent } from './shared/buttons/navigate-button/navigate-button.component';
import { PrimaryButtonComponent } from './shared/buttons/primary-button/primary-button.component';
import { SecondaryButtonComponent } from './shared/buttons/secondary-button/secondary-button.component';
import { CardOptionComponent } from './shared/card-option/card-option.component';
import { CardComponent } from './shared/card/card.component';
import { CheckBoxComponent } from './shared/check-box/check-box.component';
import { CalendarDatepickerComponent } from './shared/date-picker/calendar-datepicker/calendar-datepicker.component';
import { DatepickerComponent } from './shared/date-picker/datepicker.component';
import { DynamicTableComponent } from './shared/dynamic-table/dynamic-table.component';
import { ErrorComponent } from './shared/error/error.component';
import { FleetTableComponent } from './shared/fleet-table/fleet-table.component';
import { HistoryItemComponent } from './shared/history/history-item/history-item.component';
import { HistoryComponent } from './shared/history/history.component';
import { InfoboxComponent } from './shared/infobox/infobox.component';
import { InputComponent } from './shared/input/input.component';
import { PasswordValidationComponent } from './shared/input/password-validation/password-validation.component';
import { LoggedUserComponent } from './shared/logged-user/logged-user.component';
import { MenuItemComponent } from './shared/menu/menu-item/menu-item.component';
import { MenuComponent } from './shared/menu/menu.component';
import { ModalConfirmationAttachComponent } from './shared/modal-confirmation-attach/modal-confirmation-attach.component';
import { ModalConfirmationComponent } from './shared/modal-confirmation/modal-confirmation.component';
import { ModalGenericComponent } from './shared/modal-generic/modal-generic.component';
import { ModalComponent } from './shared/modal/modal.component';
import { NotFoundComponent } from './shared/not-found/not-found.component';
import { PaginatorComponent } from './shared/paginator/paginator.component';
import { SummaryComponent } from './pages/dfdv/dfdv-sumarize/summary/summary.component';
import { SummaryInfoTemplateComponent } from './pages/dfdv/dfdv-sumarize/summary/summary-info-template/summary-info-template.component';
import { RoundButtonsComponent } from './shared/round-buttons/round-buttons.component';
import { SearchModalComponent } from './shared/search/search-modal/search-modal.component';
import { SearchComponent } from './shared/search/search.component';
import { SelectComponent } from './shared/select/select.component';
import { SidenavComponent } from './shared/sidenav/sidenav.component';
import { SkeletonBoxComponent } from './shared/skeleton/skeleton-box/skeleton-box.component';
import { SkeletonInputComponent } from './shared/skeleton/skeleton-input/skeleton-input.component';
import { SkeletonLineComponent } from './shared/skeleton/skeleton-line/skeleton-line.component';
import { SkeletonTextComponent } from './shared/skeleton/skeleton-text/skeleton-text.component';
import { SkeletonComponent } from './shared/skeleton/skeleton.component';
import { StatusComponent } from './shared/status/status.component';
import { StepComponent } from './shared/step/step.component';
import { StepperComponent } from './shared/stepper/stepper.component';
import { TabItemComponent } from './shared/tab/tab-item/tab-item.component';
import { TabComponent } from './shared/tab/tab.component';
import { TextAreaComponent } from './shared/text-area/text-area.component';
import { ToogleComponent } from './shared/toogle/toogle.component';
import { TooltipComponent } from './shared/tooltip/tooltip.component';
import { CryptoService } from 'src/app/services/Encryption/CryptoService';

registerLocaleData(localePt, 'pt');

@NgModule({
  declarations: [
    AppComponent,
    SolicitationComponent,
    SolicitationListComponent,
    SolicitationDetailComponent,
    SolicitationNewComponent,
    HomeComponent,
    CheckBoxComponent,
    InputComponent,
    TextAreaComponent,
    ButtonsComponent,
    RoundButtonsComponent,
    CardComponent,
    SidenavComponent,
    SelectComponent,
    AttachComponent,
    PaginatorComponent,
    SolicitationFilterComponent,
    DatepickerComponent,
    CalendarDatepickerComponent,
    StepperComponent,
    StepComponent,
    ModalComponent,
    PrimaryButtonComponent,
    SecondaryButtonComponent,
    LoginComponent,
    SignInComponent,
    SignUpComponent,
    NavigateButtonComponent,
    PreRegistrationComponent,
    CompanyStepComponent,
    InfoboxComponent,
    PasswordValidationComponent,
    AddressStepComponent,
    ContactStepComponent,
    ModalGenericComponent,
    SolicitationApprovalModalComponent,
    AccessDeniedComponent,
    AdminComponent,
    SearchComponent,
    ToogleComponent,
    AnalystComponent,
    OperatorComponent,
    OperatorListComponent,
    OperatorListItemComponent,
    PreRegisterOperatorListComponent,
    PreRegisterOperatorListItemComponent,
    CategoryComponent,
    SubcategoryComponent,
    UnitComponent,
    UnitNewComponent,
    SearchModalComponent,
    UnitListComponent,
    UnitListDetailComponent,
    UnitListCardComponent,
    StatusComponent,
    CategoryNewComponent,
    CategoryListComponent,
    CategoryListItemComponent,
    SubcategoryNewComponent,
    ValueLimitComponent,
    CardOptionComponent,
    CnpjPipe,
    PhonePipe,
    AnalystNewComponent,
    TabComponent,
    TabItemComponent,
    HistoryComponent,
    HistoryItemComponent,
    SolicitationApprovementComponent,
    SolicitationHistoryComponent,
    SolicitationDataComponent,
    OperatorNewComponent,
    SubcategoryListComponent,
    SubcategoryListCardComponent,
    SubcategoryListDetailComponent,
    AnalystListComponent,
    AnalystListItemComponent,
    AnalystListCardComponent,
    ModalConfirmationComponent,
    SkeletonComponent,
    SkeletonLineComponent,
    SkeletonTextComponent,
    SkeletonInputComponent,
    SkeletonBoxComponent,
    LostPasswordComponent,
    NotFoundComponent,
    ErrorComponent,
    CoverImageComponent,
    LoggedUserComponent,
    TooltipComponent,
    IconButtonComponent,
    DfdvSummarizeComponent,
    PlantComponent,
    ScopeFeeComponent,
    TaxComponent,
    ChargeComponent,
    CumulativeIncidenceComponent,
    DriverFactorComponent,
    MenuComponent,
    MenuItemComponent,
    GeneralCostsComponent,
    OfficeExpensesComponent,
    AdministrativeTeamUniformComponent,
    OperationalTeamUniformComponent,
    MaintenanceAndWarehouseTeamUniformComponent,
    DynamicTableComponent,
    SupportTeamComponent,
    SupportFleetComponent,
    VariableCostsComponent,
    TiresComponent,
    MaintenanceComponent,
    ConsumptionComponent,
    VariableCostsFleetComponent,
    LgpdInfoComponent,
    OperatorDataComponent,
    OperatorPimsComponent,
    OperatorPimsDetailComponent,
    OperatorPimsCardComponent,
    OperatorPimsRegisterComponent,
    BreadcrumbComponent,
    OperatorPimsUpdateComponent,
    ChargesComponent,
    OperationComponent,
    OperationCardComponent,
    OperationDetailComponent,
    ScopeComponent,
    ScopeConsumptionComponent,
    ScopeRentalComponent,
    ScopeEquipmentComponent,
    OperationNewComponent,
    BenefitComponent,
    OperationUpdateComponent,
    ProfitComponent,
    UnitCategoryNewComponent,
    UnitCategoryDetailComponent,
    UnitCategoryCardComponent,
    RvParameterComponent,
    DfdvCreateModalComponent,
    DfdvComponent,
    DfdvFilterComponent,
    DfdvListComponent,
    DfdvDetailComponent,
    DfdvRouteComponent,
    FixedCostsComponent,
    FixedCostsFilterComponent,
    FixedCostsListComponent,
    FixedCostsContentComponent,
    FixedCostsCategoryComponent,
    FixedCostUnitComponent,
    FixedCostsHeaderComponent,
    FixedCostsReleasesComponent,
    SumarizeHeaderComponent,
    CumulativeIncidenceOplComponent,
    FixedCostHistoryComponent,
    ModalConfirmationAttachComponent,
    SumarizeInfoComponent,
    FleetComponent,
    FleetSpecificationsComponent,
    FleetTableComponent,
    FleetTableTractorComponent,
    FleetTableTrailerComponent,
    FleetTableTaxComponent,
    FleetTableCapitalComponent,
    FleetTableProfitComponent,
    FleetModalNewConfigComponent,
    SupportTeamCardComponent,
    SupportTeamTotalComponent,
    SummaryComponent,
    SummaryInfoTemplateComponent,
    OperationalTeamComponent,
    TableComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    DfdvSummarizeRoutingModule,
    FixedCostsRoutingModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgClickOutsideDirective,
    NgxCurrencyDirective,
    NgxMaskDirective,
    NgxMaskPipe,
    RecaptchaModule,
    RecaptchaFormsModule,
    BreadcrumbModule,
    StoreModule.forRoot({
      dfdv: dfdvReducer,
      loading: loadingReducer,
      fixedCost: fixedCostReducer,
      loadingFixedCost: loadingFixedCostReducer,
      errorFixedCost: errorFixedCostReducer,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    EffectsModule.forRoot([DfdvEffects, FixedCostEffects])
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.RecaptchaSiteKey,
      } as RecaptchaSettings,
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR',
    },
    CurrencyPipe,
    DecimalPipe,
    provideNgxMask(),
    provideHttpClient(withInterceptorsFromDi()),
    CryptoService,
  ]
})
export class AppModule { }
