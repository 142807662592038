import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService  {
  constructor(
    private router: Router,
    private authService: AuthService,
    private storageService: StorageService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const endpoint = this.getConfiguredUrl(route);
    const canActivateRoute = this.checkPermission(endpoint);

    if (!canActivateRoute) {
      const canActivateAccessDenied = this.checkPermission('access-denied');

      if (canActivateAccessDenied) {
        this.router.navigate(['access-denied']);
      } else {
        this.authService.logout();
      }
    }

    return canActivateRoute;
  }

  getConfiguredUrl(route: ActivatedRouteSnapshot) {
    let url = route.pathFromRoot
      .filter((v) => v.routeConfig)
      .map((v) => v.routeConfig!.path)
      .join('/');

    url = url.replace('//', '/');

    if (url.slice(-1) === '/') {
      url = url.slice(0, url.length - 1);
    }

    return url;
  }

  checkPermission(endpoint: string) {
    const scope = this.storageService.getScope();
    return scope.permissions?.some((permission) => endpoint.endsWith(permission.endpoint));
  }
}
