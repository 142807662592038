import {
  Component, OnInit, Input, Output, EventEmitter,
} from '@angular/core';
import moment from 'moment';
import { DatepickerIntervalModel } from '../models/datepicker-interval.model';

@Component({
  selector: 'app-calendar-datepicker',
  templateUrl: './calendar-datepicker.component.html',
  styleUrls: ['./calendar-datepicker.component.scss'],
})
export class CalendarDatepickerComponent implements OnInit {
  @Input() interval:boolean = false;
  @Input() monthCalendar: boolean = false;

  @Output() dateInterval: EventEmitter<any> = new EventEmitter();

  selectedDate: moment.Moment | undefined;
  dateFrom: moment.Moment | undefined;
  dateTo: moment.Moment | undefined;

  public date = moment();
  public dateNext = moment().clone().add(1, 'M');
  public daysArr:any;
  public daysArrNext:any;
  public monthArr: string[] = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];
  public yearArr:moment.Moment[] = [];

  monthtable:boolean = false;
  yeartable:boolean = false;
  daystable:boolean = true;

  monthtableNext:boolean = false;
  yeartableNext:boolean = false;
  daystableNext:boolean = true;

  ngOnInit(): void {
    if (!this.monthCalendar) {
      this.daysArr = this.createCalendar(this.date);
      if (this.interval) {
        this.daysArrNext = this.createCalendar(this.date.clone().add(1, 'M'));
      }
      this.setMonth(this.date.month(), false);
    } else {
      this.date.set('D', 1);
      this.dateNext.set('D', this.dateNext.daysInMonth());
      this.monthHeaderClick(this.interval);
      this.monthHeaderClick(!this.interval);
    }
    this.generateYearTable(this.date);
  }

  public setDate(day:moment.Moment) {
    if (!this.interval) {
      this.selectedDate = day.clone();
      if (this.selectedDate.month() !== this.date.month()) {
        this.setMonth(this.selectedDate.month());
        return;
      }
    } else {
      if (this.dateFrom !== undefined && this.dateTo !== undefined) {
        this.dateFrom = undefined;
        this.dateTo = undefined;
      }
      if (this.dateFrom === undefined) {
        this.dateFrom = day.clone();
      } else if (day < this.dateFrom) {
        this.dateFrom = day.clone();
      } else if (this.dateTo === undefined) {
        this.dateTo = day.clone();
      }

      this.date.locale('pt-br');
    }
    const dateInterval = new DatepickerIntervalModel();

    if (!this.monthCalendar) {
      dateInterval.startDate = this.dateFrom?.format('DD/MM/YYYY').toString()
      ?? this.selectedDate?.format('DD/MM/YYYY').toString() ?? '';

      dateInterval.endDate = this.dateTo?.format('DD/MM/YYYY').toString() ?? '';
    } else {
      this.dateFrom?.set('D', 1);
      this.dateTo?.set('D', this.dateTo.daysInMonth());

      dateInterval.startDate = this.dateFrom?.format('MM/DD/YYYY').toString().toLocaleUpperCase()
      ?? this.selectedDate?.format('MM/DD/YYYY').toString().toLocaleUpperCase() ?? '';

      dateInterval.endDate = this.dateTo?.format('MM/DD/YYYY').toString().toLocaleUpperCase() ?? '';
    }

    this.dateInterval.emit(dateInterval);
  }

  public setMonth(index:number, interval:boolean = false) {
    if (interval) {
      this.monthtableNext = this.monthCalendar;
      this.yeartableNext = false;
      this.daystableNext = !this.monthCalendar;
      this.dateNext.set('M', index);
      if (this.dateNext.month() <= this.date.month()) {
        this.date.set('M', index - 1);
      }
      this.daysArr = this.createCalendar(this.date);
      this.daysArrNext = this.createCalendar(this.dateNext);
    } else {
      this.monthtable = this.monthCalendar;
      this.yeartable = false;
      this.daystable = !this.monthCalendar;
      this.date.set('M', index);

      if (this.monthCalendar) {
        this.date.set('D', 1);
        this.dateNext.set('D', this.dateNext.daysInMonth());
        this.setDate(this.date);
        return;
      }

      if (this.date.month() >= this.dateNext.month()) {
        this.dateNext.set('M', index + 1);
      }
      this.daysArr = this.createCalendar(this.date);
      this.daysArrNext = this.createCalendar(this.dateNext);
    }
  }

  public setYear(year:moment.Moment, interval:boolean) {
    if (interval) {
      this.monthtableNext = this.monthCalendar;
      this.yeartableNext = false;
      this.daystableNext = !this.monthCalendar;

      this.dateNext.set('y', year.year());
      if (this.dateNext <= this.date) {
        this.date.set('y', year.year());
        this.date.set('M', this.dateNext.month() - 1);
      }
      this.daysArr = this.createCalendar(this.date);
      this.daysArrNext = this.createCalendar(this.dateNext);
    } else {
      this.monthtable = this.monthCalendar;
      this.yeartable = false;
      this.daystable = !this.monthCalendar;

      this.date.set('y', year.year());
      if (this.date >= this.dateNext) {
        this.dateNext.set('y', year.year());
        this.dateNext.set('M', this.date.month() + 1);
      }
      this.daysArr = this.createCalendar(this.date);
      this.daysArrNext = this.createCalendar(this.dateNext);

      this.date.set('y', year.year());
      this.daysArr = this.createCalendar(this.date);
    }
  }

  public monthHeaderClick(interval:boolean) {
    if (interval) {
      this.daystableNext = false;
      this.yeartableNext = false;
      this.monthtableNext = true;
    } else {
      this.daystable = false;
      this.yeartable = false;
      this.monthtable = true;
    }
  }
  public yearHeaderClick(interval:boolean) {
    if (interval) {
      this.daystableNext = false;
      this.monthtableNext = false;
      this.yeartableNext = true;
    } else {
      this.daystable = false;
      this.monthtable = false;
      this.yeartable = true;
    }
  }

  public isSelected(day:moment.Moment) {
    const momentFormated = moment(day).format('M D Y');
    const selectedDateFormated = this.selectedDate?.format('M D Y');
    const dateFromFormated = this.dateFrom?.format('M D Y');
    const dateToFormated = this.dateTo?.format('M D Y');

    return momentFormated === selectedDateFormated
      || momentFormated === dateFromFormated
      || momentFormated === dateToFormated
      || (
        moment(day) > moment(this.dateFrom)
        && moment(day) < moment(this.dateTo)
        && this.dateTo !== undefined
      );
  }

  public todayCheck(day:moment.Moment) {
    if (!day) { return false; }
    return moment().format('M D Y') === day.format('M D Y');
  }

  public monthCheck(month: string) {
    if (month === this.date.locale('pt-br').format('MMMM').substring(0, 3).toUpperCase()) { return true; }
    return false;
  }

  public nextMonth_Year(interval:boolean) {
    if (interval) {
      if (!this.yeartableNext) {
        this.dateNext.add(1, 'M');
        this.daysArrNext = this.createCalendar(this.dateNext);
      } else {
        this.dateNext.add(20, 'y');
        this.generateYearTable(this.dateNext);
      }
    } else if (!this.yeartable) {
      this.date.add(1, 'M');
      if (moment(this.date).isAfter(this.dateNext.clone().subtract(1, 'd'))) {
        this.dateNext.add(1, 'M');
      }
      this.daysArr = this.createCalendar(this.date);
      this.daysArrNext = this.createCalendar(this.dateNext);
    } else {
      this.date.add(20, 'y');
      this.generateYearTable(this.date);
    }
  }

  public previousMonth_Year(interval:boolean) {
    if (interval) {
      if (!this.yeartableNext) {
        this.dateNext.subtract(1, 'M');
        if (moment(this.dateNext).isBefore(this.date.clone().add(1, 'd'))) {
          this.date.subtract(1, 'M');
        }
        this.daysArr = this.createCalendar(this.date);
        this.daysArrNext = this.createCalendar(this.dateNext);
      } else {
        this.dateNext.subtract(20, 'y');
        this.generateYearTable(this.dateNext);
      }
    } else if (!this.yeartable) {
      this.date.subtract(1, 'M');
      this.daysArr = this.createCalendar(this.date);
    } else {
      this.date.subtract(20, 'y');
      this.generateYearTable(this.date);
    }
  }

  public generateYearTable(year:moment.Moment) {
    this.yearArr = [];

    for (let i = 0; i < 20; i++) {
      this.yearArr.push(year.clone().add(i, 'y'));
    }
  }

  public createCalendar(month:moment.Moment) {
    const daysInMonth = Array(month.daysInMonth());
    const previousMonth = month.clone().subtract(1, 'month');
    const nextMonth = month.clone().add(1, 'month');
    const days:moment.Moment[] = [];

    for (let i = 1; i <= daysInMonth.length; i++) {
      days.push(month.clone().date(i));
    }

    if (!(previousMonth.endOf('M').day() === 6)) {
      for (let p = previousMonth.daysInMonth();
        p > previousMonth.daysInMonth() - previousMonth.endOf('M').day() - 1;
        p--) {
        const currentDay = () => previousMonth.clone().date(p);
        days.unshift(currentDay());
      }
    }

    for (let n = 0; n < (14 - nextMonth.startOf('M').weekday()); n++) {
      if (days.length === 42) { break; }

      const currentDay = () => moment(nextMonth).date(n + 1);
      days.push(currentDay());
    }

    return days;
  }
}
