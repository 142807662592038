import { Utils } from 'src/app/common/utils/utils';
/* eslint-disable max-len */
import {
  Component, EventEmitter, Input, OnChanges, Output, SimpleChanges,
} from '@angular/core';
import moment from 'moment';
import { FleetTractorModel } from 'src/app/services/dfdv/fleet/models/fleet-tractor.model';
import { FleetTableRowStatusEnum } from 'src/app/shared/fleet-table/enums/fleet-table.enum';

@Component({
  selector: 'app-fleet-table-tractor',
  templateUrl: './fleet-table-tractor.component.html',
  styleUrls: ['./fleet-table-tractor.component.scss'],
})
export class FleetTableTractorComponent implements OnChanges {
  @Input() isLoading: boolean = false;

  @Input() tractorList: FleetTractorModel[] = [];
  @Input() activeActions: boolean = false;
  @Output() newConfig: EventEmitter<number> = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tractorList']) {
      this.buildTable();
    }
  }
  constructor(public utils: Utils) {
  }

  public tractorHeader: string[] = [
    'ATIVO/PARADO',
    'QUANTIDADE',
    'STATUS FINANCIAMENTO',
    'FABRICANTE',
    'MODELO',
    'TAXA REMUNERAÇÃO CAPITAL',
    'VALOR CAVALO MECÂNICO',
    'VALOR DO PNEU',
    'VALOR IMPOSTO (PIS/COFINS)',
    'VALOR IMPOSTO (ICMS)',
    'VALOR LÍQUIDO P/ DEPRECIAÇÃO',
    'VALOR LÍQUIDO P/ REMUNERAÇÃO CAPITAL',
    'ANO CAVALO MECÂNICO',
    'MÊS/ANO ENTRADA',
    'PERÍODO DEPRECIAÇÃO (EM MESES)',
    'CICLO DE UTILIZAÇÃO',
    'RESIDUAL CAVALO MECÂNICO',
  ];

  public tractorContent: string[][] = [];

  public tractorActiveContent: string[] = [];

  public tractorStopedContent: string[] = [];

  public tractorRowStatus: FleetTableRowStatusEnum[] = [];

  buildTable() {
    const columnTotalsActive = Array(this.tractorHeader.length - 1).fill(0);
    const columnTotalsStopped = Array(this.tractorHeader.length - 1).fill(0);

    if (!this.tractorList?.length) {
      const initialValues = Array(this.tractorHeader.length - 1).fill('-');
      this.tractorActiveContent = initialValues;
      this.tractorStopedContent = initialValues;
      return;
    }

    this.tractorContent = [];
    this.tractorActiveContent = [];
    this.tractorStopedContent = [];
    this.tractorRowStatus = [];

    this.tractorList.forEach((x, index) => {
      const keys = Object.keys(x).filter((z) => z !== 'active');

      if (x.active) {
        keys.forEach((key, i) => {
          if (key === 'tractorYear') {
            columnTotalsActive[i] = 0;
          } else if (typeof x[key] === 'number') {
            columnTotalsActive[i] += x[key];
          }
        });
      } else {
        keys.forEach((key, i) => {
          if (key === 'tractorYear') {
            columnTotalsStopped[i] = 0;
          } else if (typeof x[key] === 'number') {
            columnTotalsStopped[i] += x[key];
          }
        });
      }

      this.tractorContent.push([
        (index + 1).toString(),
        x.quantity.toString(),
        x.tractorFinancingStatus,
        x.tractorBrand,
        x.tractorModel,
        this.utils.numberFormatter(x.capitalRemuneration, 2, 2) + ' %',
        this.utils.toMoney(x.tractorCost.toString()),
        this.utils.toMoney(x.tractorTireCost.toString()),
        this.utils.toMoney(x.tractorPisCofinsCost.toString()),
        this.utils.toMoney(x.tractorIcmsCost.toString()),
        this.utils.toMoney(x.tractorNetValueDepreciation.toString()),
        this.utils.toMoney(x.tractorNetValueRemuneration.toString()),
        x.tractorYear.toString(),
        moment(x.tractorAcquisitionDate).locale('pt-br').format("MMMM/YYYY").toUpperCase(),
        x.tractorDepreciationPeriod.toString(),
        this.utils.numberFormatter(x.tractorUsageCycle, 2, 2),
        this.utils.numberFormatter(x.tractorResidual, 2, 2) + ' %',
      ]);

      this.tractorRowStatus.push(x.active ? FleetTableRowStatusEnum.ACTIVE : FleetTableRowStatusEnum.STOPED);
    });

    const activeItemCount = this.tractorList.filter((x) => x.active).length;
    const columnActiveAverages = columnTotalsActive.map((total) => (activeItemCount > 0 ? total / activeItemCount : 0));
    this.tractorActiveContent = columnActiveAverages.map((avg) => (avg === 0 ? '-' : avg.toFixed(2)));
    for (let i = 0; i < this.tractorActiveContent.length; i++) {
      if (i === 0 && this.tractorActiveContent[i] !== '-') {
        this.tractorActiveContent[i] = columnTotalsActive[i];
      } else if ((i === this.tractorActiveContent.length - 2 || i === this.tractorActiveContent.length - 3) && this.tractorActiveContent[i] !== '-') {
        this.tractorActiveContent[i] = this.tractorActiveContent[i].replace('.', ',');
      } else if (i === this.tractorActiveContent.length - 1 && this.tractorActiveContent[i] !== '-') {
        this.tractorActiveContent[i] = this.tractorActiveContent[i] + ' %';
      } else if ((i === this.tractorActiveContent.length - 12) && this.tractorActiveContent[i] !== '-') {
        this.tractorActiveContent[i] = '-';
      } else if (this.tractorActiveContent[i] !== '-' && i > 1) {
        this.tractorActiveContent[i] = this.utils.toMoney(this.tractorActiveContent[i]);
      }
    }

    const stoppedItemCount = this.tractorList.filter((x) => !x.active).length;
    const columnStoppedAverages = columnTotalsStopped.map((total) => (stoppedItemCount > 0 ? total / stoppedItemCount : 0));
    this.tractorStopedContent = columnStoppedAverages.map((avg) => (avg === 0 ? '-' : avg.toFixed(2)));
    for (let i = 0; i < this.tractorStopedContent.length; i++) {
      if (i === 0 && this.tractorStopedContent[i] !== '-') {
        this.tractorStopedContent[i] = columnTotalsStopped[i];
      } else if ((i === this.tractorStopedContent.length - 2 || i === this.tractorStopedContent.length - 3) && this.tractorStopedContent[i] !== '-') {
        this.tractorStopedContent[i] = this.tractorStopedContent[i].replace('.', ',');
      } else if (i === this.tractorStopedContent.length - 1 && this.tractorStopedContent[i] !== '-') {
        this.tractorStopedContent[i] = this.tractorStopedContent[i] + ' %'
      } else if ((i === this.tractorActiveContent.length - 12) && this.tractorActiveContent[i] !== '-') {
        this.tractorActiveContent[i] = '-';
      } else if (this.tractorStopedContent[i] !== '-' && i > 1) {
        this.tractorStopedContent[i] = this.utils.toMoney(this.tractorStopedContent[i]);
      }
    }
  }

  handleNewConfig(index?: number) {
    if (!this.activeActions) {
      return;
    }

    if (index !== undefined && index >= 0) {
      this.newConfig.emit(index);
    } else {
      this.newConfig.emit();
    }
  }
}
