import {
  Component,
  HostListener,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Utils } from 'src/app/common/utils/utils';

import { SideIconTypeEnum } from 'src/app/shared/input/enums/side-icon-type.enum';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnChanges, OnInit {
  @Input() multiControl = new FormControl(Array<string>());
  @Input() singleControl = new FormControl('');
  @Input() options: Array<string> = [];
  @Input() errorMessage: string = '';
  @Input() showErrorMessage: boolean = true;
  @Input() selected: string[] = [];
  @Input() multi: boolean = false;
  @Input() field: string = '';
  @Input() placeholder: string = '';
  @Input() value: string = '';
  @Input() disabled: boolean = false;
  @Input() description!: string;
  @Input() id: string = '';
  @Input() fieldVisible: boolean = true;
  @Input() height: number = 3;

  @Output() statusChanged: EventEmitter<string[]> = new EventEmitter();
  @Output() selectedItemsEmpty: EventEmitter<any> = new EventEmitter();

  eSideIconType = SideIconTypeEnum;

  tabObservable$!: Observable<Event>;
  tabSubscription$!: Subscription;

  filtredOptions: Array<any> = [];
  error: boolean = false;
  onFocus = false;

  width = 25;

  heightSelect = `${(this.height * 0.67).toString()}rem`;

  search = new FormControl('');
  checkedAll: boolean = false;

  constructor(public utils: Utils) {}

  @HostListener('document:keydown', ['$event'])
  documentKeydownEvent(event: KeyboardEvent) {
    if (event.key === 'Escape' || event.key === 'Tab') {
      this.blurInput();
    }
  }

  ngOnInit(): void {
    this.handleForm();

    if (this.disabled) {
      this.multiControl.disable();
      this.singleControl.disable();
    }

    this.singleControl.statusChanges.subscribe(
      this.controlStatusChanges.bind(this),
    );
    this.multiControl.statusChanges.subscribe(
      this.controlStatusChanges.bind(this),
    );
  }

  controlStatusChanges() {
    this.statusChanged.emit(this.selected);
    if (this.multi) {
      this.error = this.multiControl.status === 'INVALID';
      if (this.multiControl.value && this.multiControl.value.length > 0) {
        this.multiControl.markAsDirty();
      }
    } else {
      this.error = this.singleControl.status === 'INVALID';
      if (this.singleControl.value) {
        this.singleControl.markAsDirty();
      }
    }
  }

  ngOnChanges(): void {
    this.filterOptions(this.value);
  }

  @HostListener('window:resize', ['$event'])
  onResized(event: any) {
    this.width = event.newRect.width;
  }

  focusInput() {
    this.onFocus = true;
  }

  blurInput() {
    this.onFocus = false;
    this.search.setValue('');
    this.filterOptions('');
  }

  isChecked(optionSelected: string): boolean {
    return this.selected.some((option) => option === optionSelected);
  }

  checkOption(option: string) {
    this.checkedAll = false;
    if (!this.multi) {
      this.selected = [];
    }

    this.selected.push(option);

    if (!this.multi) {
      this.blurInput();
    }

    this.handleForm();
  }

  checkAll() {
    this.checkedAll = true;
    this.selected = [];
    this.resetFormControl();
    this.filtredOptions.forEach((element) => {
      this.selected.push(element);
    });

    this.handleForm();
  }

  removeOption(optionSelected: string, event: Event) {
    this.checkedAll = false;
    this.selected = this.selected.filter((option) => option !== optionSelected);
    this.handleForm();
    this.resetFormControl();
    this.selectedEmpty(event);
  }

  removeOptionAll(event: Event) {
    this.checkedAll = false;
    this.filtredOptions.forEach((element) => {
      this.selected = this.selected.filter((option) => option !== element);
    });
    this.handleForm();
    this.resetFormControl();
    this.selectedEmpty(event);
  }

  removeAll(event: Event) {
    this.checkedAll = false;
    this.selected = [];
    this.handleForm();
    this.resetFormControl();
    this.selectedEmpty(event);
  }

  filterOptions(valueChanged: string | null) {
    if (valueChanged == null) {
      return;
    }

    this.value = valueChanged;
    this.filtredOptions = !this.value
      ? this.options
      : this.options.filter((option) => this.compareIgnoreCase(option, this.value));
  }

  compareIgnoreCase(value: string, valueCompare: string) {
    return value
      .toLocaleLowerCase()
      .replace(/\s\s+/g, ' ')
      .trim()
      .includes(valueCompare.toLocaleLowerCase().replace(/\s\s+/g, ' ').trim());
  }

  handleForm() {
    if (this.multi) {
      this.multiControl.patchValue(this.selected);
    } else {
      this.singleControl.patchValue(this.selected[0] || '');
    }
  }

  selectedEmpty(event: Event) {
    if (this.selected?.length === 0) {
      this.selectedItemsEmpty.emit(event);
    }
  }

  resetFormControl() {
    if (this.multi && this.selected.length === 0) {
      this.multiControl.reset();
    } else if (this.selected.length === 0) {
      this.singleControl.reset();
    }
  }
}
