import { CreateUnitCategoryModel } from 'src/app/services/unit/models/create-unit-category-model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/common/services/base.service';
import { CreateUnitModel } from './models/create-unit-model';
import { GetUnitModel } from './models/get-unit-model';
import { UpdateUnitModel } from './models/update-unit-model';
import { GetUnitCategoryModel } from './models/get-unit-category-model';
import { CryptoService } from 'src/app/services/Encryption/CryptoService';

@Injectable({
  providedIn: 'root',
})
export class UnitService extends BaseService {
  constructor(private http: HttpClient, private cryptoService: CryptoService) {
    super('units');
  }

  getUnits() {
    return this.http.get<GetUnitModel[]>(this.url);
  }

  getActiveUnits() {
    return this.http.get<GetUnitModel[]>(`${this.url}/actives`);
  }

  getUnitCategory(unitId: string, categoryId: string) {
    return this.http.get<GetUnitCategoryModel>(`${this.url}/${unitId}/categories/${categoryId}`);
  }

  getUnitsByOpl(operatorId: string) {
    const operatorIdEncrypted = this.cryptoService.crypt(operatorId);
    return this.http.get<GetUnitModel[]>(`${this.url}/operators/${operatorIdEncrypted}`);
  }

  getUnitById(unitId: number) {
    return this.http.get<GetUnitModel>(`${this.url}/${unitId}`);
  }

  createUnit(request: CreateUnitModel) {
    return this.http.post<GetUnitModel>(this.url, request);
  }

  updateUnit(request: UpdateUnitModel) {
    return this.http.patch<GetUnitModel>(`${this.url}/${request.unitId}`, request);
  }

  createUnitCategory(request: CreateUnitCategoryModel) {
    return this.http.post<any>(`${this.url}/${request.unitId}/categories`, request);
  }
  updateUnitCategory(request: CreateUnitCategoryModel) {
    return this.http.patch<any>(`${this.url}/${request.unitId}/categories`, request);
  }
}
