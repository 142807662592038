import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { OperatorService } from 'src/app/services/operator/operator.service';
import { PatchCompaniesPimsModel } from 'src/app/services/pims/companies/model/patch-companies-pims.model';
import { GetCompanyGroupedPimsCnpjIdModel } from 'src/app/services/operator/models/get-company-pims-cnpj-id';
import { ModalModel } from 'src/app/pages/pre-registration/models/modal.model';
import { HttpErrorResponse } from '@angular/common/http';
import { PatchCompanyGroupedPimsModel } from '../../../../../../services/operator/models/patch-company-grouped-pims.model';
import { CryptoService } from 'src/app/services/Encryption/CryptoService';

@Component({
  selector: 'app-operator-pims-update',
  templateUrl: './operator-pims-update.component.html',
  styleUrls: ['./operator-pims-update.component.scss'],
})
export class OperatorPimsUpdateComponent implements OnInit {
  // Forms
  public formTransp = this.formBuilder.group({
    cd_transp: '',
    cnpj: '',
    description: '',
    active: false,
  });

  // Controls
  public operatorId : string = '';
  public transporterId: string = '';
  public company: GetCompanyGroupedPimsCnpjIdModel = new GetCompanyGroupedPimsCnpjIdModel();

  // Auxs
  public eButtonsColor = ButtonColorEnum;
  public showModal: boolean = false;
  public modalModel: ModalModel = new ModalModel();

  // Buttons Handler
  public deleteLoading = false;
  public updateLoading = false;
  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public service: OperatorService,
    public cryptoService: CryptoService,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.operatorId = this.cryptoService.decrypt(params.get('id') ?? '')
      this.transporterId = params.get('transporterId') ?? '';
    });

    this.getTransporter();
  }

  getTransporter() {
    this.service.getCompanyPims(this.operatorId, this.transporterId).subscribe(((result) => {
      this.formTransp.controls.active.patchValue(result.operatorPims.active);
      this.formTransp.controls.cd_transp.patchValue(result.operatorPims.transporterId.toString());
      this.formTransp.controls.cnpj.patchValue(result.operatorPims.transporterCnpj);
      this.formTransp.controls.description.patchValue(result.operatorPims.transporterName);

      this.company = result;
    }));
  }

  onDelete() {
    this.showModal = true;
  }

  onSave() {
    this.updateLoading = true;
    const companyPims = [{
      transporterCnpj: this.company.operatorPims.transporterCnpj,
      transporterId: this.company.operatorPims.transporterId,
      transporterName: this.company.operatorPims.transporterName,
      active: this.formTransp.controls.active.value,
    }] as PatchCompaniesPimsModel[];

    const groupedCompanyPims = {
      operatorId: this.company.operatorId,
      operatorName: this.company.operatorName,
      operatorPims: companyPims,
    } as PatchCompanyGroupedPimsModel;

    this.service.updateOperatorPims(groupedCompanyPims).subscribe({
      next: this.updateSuccess.bind(this),
      error: this.updateError.bind(this),
    });
  }

  onCheck() {
    this.formTransp.controls.active.patchValue(!this.formTransp.controls.active.value);
  }

  return() {
    var cnpjCrypto = this.cryptoService.crypt(this.operatorId);
    this.router.navigate([`/admin/operator/${cnpjCrypto}`]);
  }

  denyDelete() {
    this.showModal = false;
  }

  confirmDelete() {
    this.deleteLoading = true;
    this.service.deleteOperatorPims(this.operatorId, this.transporterId).subscribe({
      next: this.deleteSuccess.bind(this),
      error: this.deleteError.bind(this),
    });
  }

  clickModal() {
    if (this.modalModel.success) {
      this.return();
    } else {
      this.modalModel.showModal = false;
    }
  }

  updateSuccess() {
    this.updateLoading = false;
    this.modalModel.showModal = true;
    this.modalModel.imagePath = '../../../../../../../assets/icons/success-600.gif';
    this.modalModel.description = `A Transportadora ${this.transporterId} foi atualizada com sucesso!`;
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.success = true;
    this.modalModel.title = 'Atualização';
  }

  updateError(error: HttpErrorResponse) {
    const message = JSON.parse(JSON.stringify(error.error));
    this.updateLoading = false;
    this.modalModel.showModal = true;
    this.modalModel.imagePath = '../../../../../../../assets/icons/warning-600.gif';
    this.modalModel.description = message?.Message;
    this.modalModel.success = false;
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.title = `Não foi possivel atualizar a transportadora ${this.transporterId}`;
  }

  deleteSuccess() {
    this.deleteLoading = false;
    this.modalModel.showModal = true;
    this.modalModel.imagePath = '../../../../../../../assets/icons/success-600.gif';
    this.modalModel.description = `A Transportadora ${this.transporterId} foi excluída com sucesso!`;
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.success = true;
    this.modalModel.title = 'Delete';
  }

  deleteError(error: HttpErrorResponse) {
    const message = JSON.parse(JSON.stringify(error.error));
    this.deleteLoading = false;
    this.modalModel.showModal = true;
    this.modalModel.imagePath = '../../../../../../../assets/icons/warning-600.gif';
    this.modalModel.description = message?.Message;
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.success = false;
    this.modalModel.title = `Não foi possivel excluir a transportadora ${this.transporterId}`;
  }
}
