import {
  Subscription, debounceTime, forkJoin, tap,
} from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ModalModel } from 'src/app/pages/pre-registration/models/modal.model';
import { GetCompanySizeModel } from 'src/app/services/operator/models/get-company-size-model';
import { GetEmployeeNumberModel } from 'src/app/services/operator/models/get-employee-number-model';
import { GetOperatorModel } from 'src/app/services/operator/models/get-operator-model';
import { OperatorService } from 'src/app/services/operator/operator.service';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { ButtonsType } from 'src/app/shared/enums/Buttonstypes';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import { ExternalService } from 'src/app/common/services/external.service';
import { StateUtils } from 'src/app/common/utils/state-utils';
import { AuthService } from 'src/app/common/services/auth.service';
import { SideIconTypeEnum } from 'src/app/shared/input/enums/side-icon-type.enum';
import { UpdateOperatorModel } from '../../../../../services/operator/models/update-operator-model';
import { CryptoService } from 'src/app/services/Encryption/CryptoService';

@Component({
  selector: 'app-operator-new',
  templateUrl: './operator-new.component.html',
  styleUrls: ['./operator-new.component.scss'],
})
export class OperatorNewComponent implements OnInit, AfterViewInit {
  public eButtonsType = ButtonsType;
  public eButtonsColor = ButtonColorEnum;
  public eInputType = InputTypeEnum;
  public eInputMask = MaskTypeEnum;
  public eSideIconType = SideIconTypeEnum;

  public nFuncionarios: string[] = [];
  public porteEmpresa: string[] = [];
  public selectedCompanySize: string[] = [];
  public selectedEmployeesCount: string[] = [];
  public selectedState: string[] = [];
  public states: string[] = [];

  public operatorId!: string;
  public active: boolean = true;
  public activeSolicitation: boolean = true;
  public isPreregister: boolean = false;
  public isLoading = true;
  public loadingButton = false;
  public sending = false;
  public sendingRefuse = false;
  public enabledSending = true;
  public enabledRefuse = true;
  public enabledBack = true;
  public id: string = '';
  public loading: boolean = false;
  public error: boolean = false;
  public errorMessage: string = 'Este campo é obrigatório';

  public showModal = false;
  public modalText = '';
  public modalModel = new ModalModel();

  public emailLoading: boolean = false;
  public emailIsValid: boolean = false;
  public emailError: string = 'Informe um E-mail valido';
  public emailFromModel: string = '';
  public sub!: Subscription;

  public companyForm = this.formBuilder.group({
    cnpj: ['', Validators.required],
    corporateName: ['', Validators.required],
    fantasyName: ['', Validators.required],
    numberRegistrationState: [''],
    numberRegistrationMunicipal: [''],
    companySize: ['', Validators.required],
    employeesCount: ['', Validators.required],
  });

  public addressForm = this.formBuilder.group({
    cep: ['', Validators.required],
    uf: ['', Validators.required],
    city: ['', Validators.required],
    neighborhood: ['', Validators.required],
    address: ['', Validators.required],
    number: ['', Validators.required],
    complement: [''],
  });

  public contactForm = this.formBuilder.group({
    name: ['', Validators.required],
    email: ['', Validators.required],
    phone: ['', Validators.required],
  });

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public formBuilder: FormBuilder,
    private operatorService: OperatorService,
    private externalService: ExternalService,
    private stateUtils: StateUtils,
    private authService: AuthService,
    private cryptoService: CryptoService,
  ) {}

  ngOnInit(): void {
    this.states = this.stateUtils.getAllStatesDescriptions();

    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get('id') ?? '';
      if (this.id) {
        this.id = this.cryptoService.decrypt(this.id);
        this.loadDataWithId();
      } else {
        this.loadData();
      }
    });
  }

  ngAfterViewInit(): void {
    if (!this.isPreregister) {
      this.contactForm.controls.email.valueChanges.pipe(
        tap(() => {
          this.emailLoading = false;
          this.emailIsValid = false;
          this.emailError = 'Informe um E-mail valido';
          if (this.sub) {
            this.sub.unsubscribe();
          }
        }),
        debounceTime(1500),
        tap((value: any) => {
          if (value && value !== '' && !this.contactForm.controls.email.valid) {
            this.contactForm.controls.email.markAsPending();
          }
        }),
      ).subscribe((value) => {
        if (!value || value === '' || value === this.emailFromModel) return;
        this.emailLoading = true;
        this.validateEmail(value);
      });
    }
  }

  validateEmail(value:string) {
    this.sub = this.operatorService.validateEmailsPublic(value ?? '')
      .subscribe({
        next: (result) => {
          this.emailLoading = false;
          if (result) {
            this.emailIsValid = true;
            this.contactForm.controls.email.setErrors(null);
            this.emailFromModel = '';
          }
        },
        error: (result: HttpErrorResponse) => {
          if (result instanceof HttpErrorResponse) {
            this.emailError = result.error.Message;
          } else {
            this.emailError = 'Não foi possivel validar o e-mail digitado';
          }
          this.emailLoading = false;
          this.emailIsValid = false;
          this.contactForm.controls.email.setErrors({ corporateNameIsValid: false });
          this.emailFromModel = '';
        },
      });
  }

  fillEmployeeNumber() {
    this.operatorService.getEmployeeNumbers().subscribe({
      next: this.handleGetEmployeeNumberSuccess.bind(this),
      error: this.handleGetEmployeeNumberError.bind(this),
    });
  }

  fillCompanySize() {
    this.operatorService.getCompanySizes().subscribe({
      next: this.handleGetCompanySizeSuccess.bind(this),
      error: this.handleGetCompanySizeError.bind(this),
    });
  }

  fillOperatorData(id: string) {
    this.operatorService.getOperatorById(id).subscribe({
      next: this.handleGetOperatorSuccess.bind(this),
      error: this.handleGetOperatorSuccess.bind(this),
    });
  }

  loadDataWithId() {
    forkJoin({
      employeeNumber: this.operatorService.getEmployeeNumbers(),
      companySize: this.operatorService.getCompanySizes(),
      operator: this.operatorService.getOperatorById(this.id),
    }).subscribe({
      next: ({ employeeNumber, companySize, operator }) => {
        this.handleGetEmployeeNumberSuccess(employeeNumber);
        this.handleGetCompanySizeSuccess(companySize);
        this.handleGetOperatorSuccess(operator);
      },
      error: (error: HttpErrorResponse) => {
        this.nFuncionarios = [];
        this.porteEmpresa = [];
        this.handleGetOperatorError(error);
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  loadData() {
    forkJoin({
      employeeNumber: this.operatorService.getEmployeeNumbers(),
      companySize: this.operatorService.getCompanySizes(),
    }).subscribe({
      next: ({ employeeNumber, companySize }) => {
        this.handleGetEmployeeNumberSuccess(employeeNumber);
        this.handleGetCompanySizeSuccess(companySize);
      },
      error: () => {
        this.nFuncionarios = [];
        this.porteEmpresa = [];
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  handleGetOperatorSuccess(model: GetOperatorModel) {
    this.companyForm.controls.cnpj.patchValue(model.operatorId ?? '');
    this.companyForm.controls.corporateName.patchValue(model.companyName ?? '');
    this.companyForm.controls.fantasyName.patchValue(model.fantasyName ?? '');
    this.companyForm.controls.numberRegistrationMunicipal.patchValue(
      model.municipalRegistration ?? '',
    );
    this.companyForm.controls.numberRegistrationState.patchValue(
      model.stateRegistration ?? '',
    );
    this.companyForm.controls.employeesCount.patchValue(
      model.employeeName ?? '',
    );
    this.companyForm.controls.companySize.patchValue(model.companySize ?? '');
    this.addressForm.controls.address.patchValue(model.street ?? '');
    this.addressForm.controls.number.patchValue(model.number ?? '');
    this.addressForm.controls.complement.patchValue(model.complement ?? '');
    this.addressForm.controls.neighborhood.patchValue(model.district ?? '');
    this.addressForm.controls.cep.patchValue(model.zipCode ?? '');
    this.addressForm.controls.city.patchValue(model.city ?? '');
    this.addressForm.controls.uf.patchValue(model.state ?? '');
    this.contactForm.controls.name.patchValue(model.contactName ?? '');
    this.contactForm.controls.email.patchValue(model.email ?? '');
    this.emailFromModel = model.email ?? '';
    this.contactForm.controls.phone.patchValue(model.phone ?? '');
    this.active = model.active;
    this.activeSolicitation = model.createSolicitationAllowed;

    if (this.companyForm.controls.companySize.value) {
      this.selectedCompanySize.push(
        this.companyForm.controls.companySize.value ?? '',
      );
    }
    if (this.companyForm.controls.employeesCount.value) {
      this.selectedEmployeesCount.push(
        this.companyForm.controls.employeesCount.value ?? '',
      );
    }
    if (this.addressForm.controls.uf) {
      this.selectedState.push(this.addressForm.controls.uf.value ?? '');
    }
    this.isPreregister = model.operatorProfileId == null;
  }

  handleGetOperatorError(error: HttpErrorResponse) {
    if (error.status === 404) {
      this.authService.redirectToAccessDenied();
    } else {
      const message = JSON.parse(JSON.stringify(error?.error));
      this.modalModel.buttonLabel = 'Entendi';
      this.modalModel.description = message?.Message ?? message;
      this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
      this.modalModel.title = 'Erro ao obter os dados';
      this.modalModel.success = true;
      this.showModal = true;
    }
  }

  handleToggleCheck() {
    this.active = !this.active;
  }

  handleSolicitationToggleCheck() {
    this.activeSolicitation = !this.activeSolicitation;
  }

  handleGetEmployeeNumberSuccess(res: GetEmployeeNumberModel[]) {
    res.forEach((element) => {
      this.nFuncionarios.push(element.name);
    });
  }

  handleGetEmployeeNumberError(res: HttpErrorResponse) {}

  handleGetCompanySizeSuccess(res: GetCompanySizeModel[]) {
    res.forEach((element) => {
      this.porteEmpresa.push(element.name);
    });
  }

  handleGetCompanySizeError(res: HttpErrorResponse) {}

  searchAddress(search: boolean) {
    const cep = this.addressForm.controls.cep.value ?? '';

    if (!search || !cep || cep.length !== 8) return;

    this.loading = true;
    this.error = false;
    this.externalService.searchAddressByCep(cep).subscribe({
      next: (res) => {
        this.loading = false;
        if (res.erro) {
          this.errorMessage = 'CEP não encontrado';
          this.error = true;
        } else {
          this.errorMessage = '';
          this.error = false;

          this.addressForm.controls.city.patchValue(res.localidade);
          this.addressForm.controls.neighborhood.patchValue(res.bairro);
          this.addressForm.controls.address.patchValue(res.logradouro);
          this.addressForm.controls.number.patchValue('');
          this.addressForm.controls.complement.patchValue('');

          const state = this.stateUtils.getStateDescriptionByUF(res.uf);
          if (state) {
            this.selectedState = [state];
            this.addressForm.controls.uf.patchValue(state);
          }
        }
      },
      error: () => {
        this.loading = false;
        this.error = true;
        this.errorMessage = 'Erro ao obter o CEP';
      },
    });
  }

  submit() {
    const request = {
      operatorId: this.companyForm.controls.cnpj.value ?? '',
      companyName: this.companyForm.controls.corporateName.value ?? '',
      fantasyName: this.companyForm.controls.fantasyName.value ?? '',
      municipalRegistration:
        this.companyForm.controls.numberRegistrationMunicipal.value ?? '',
      stateRegistration:
        this.companyForm.controls.numberRegistrationState.value ?? '',
      employeeNumberName: this.companyForm.controls.employeesCount.value ?? '',
      companySizeName: this.companyForm.controls.companySize.value ?? '',
      street: this.addressForm.controls.address.value ?? '',
      number: this.addressForm.controls.number.value ?? '',
      complement: this.addressForm.controls.complement.value ?? '',
      district: this.addressForm.controls.neighborhood.value ?? '',
      zipCode: this.addressForm.controls.cep.value ?? '',
      city: this.addressForm.controls.city.value ?? '',
      state: this.addressForm.controls.uf.value ?? '',
      contactName: this.contactForm.controls.name.value ?? '',
      email: this.contactForm.controls.email.value ?? '',
      phone: this.contactForm.controls.phone.value ?? '',
      active: this.active,
      createSolicitationAllowed: this.activeSolicitation,
    } as UpdateOperatorModel;

    this.loadingButton = true;
    this.enabledBack = false;
    this.operatorService.updateOperator(request).subscribe({
      next: this.handleOperatorSuccess.bind(this),
      error: this.handleOperatorError.bind(this),
    });
  }

  handleOperatorSuccess(res: UpdateOperatorModel) {
    this.loadingButton = false;
    this.enabledBack = true;
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.description = `Empresa ${this.companyForm.controls.fantasyName.value} foi atualizada com sucesso`;
    this.modalModel.imagePath = '../../../assets/icons/approve-modal.svg';
    this.modalModel.title = 'Dados atualizados com sucesso!';
    this.modalModel.success = true;
    this.showModal = true;
  }

  handleOperatorError(res: HttpErrorResponse) {
    this.loadingButton = false;
    this.enabledBack = true;
    const message = JSON.parse(JSON.stringify(res.error));
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.description = message?.Message;
    this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
    this.modalModel.title = 'Não foi possível realizar a atualização!';
    this.modalModel.success = false;
    this.showModal = true;
  }

  clickModal() {
    this.showModal = false;
    if (this.modalModel.success) {
      this.router.navigate(['/admin/operator']);
    }
  }

  goBack() {
    this.router.navigate(['/admin/operator']);
  }

  accept() {
    if (this.id) {
      this.sending = true;
      this.enabledRefuse = false;
      this.enabledBack = false;
      this.operatorService.updateOperatorApprove(this.id).subscribe({
        next: this.acceptSuccess.bind(this),
        error: this.acceptError.bind(this),
      });
    }
  }

  acceptSuccess(res: GetOperatorModel) {
    this.sending = false;
    this.enabledRefuse = true;
    this.enabledBack = true;
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.description = 'A partir de agora a empresa já conseguirá acessar o sistema.';
    this.modalModel.imagePath = '../../../assets/icons/approve-modal.svg';
    this.modalModel.title = 'Pré-cadastro aceito com sucesso!';
    this.modalModel.success = true;
    this.showModal = true;
  }

  acceptError(error: HttpErrorResponse) {
    this.sending = false;
    this.enabledRefuse = true;
    this.enabledBack = true;
    const message = JSON.parse(JSON.stringify(error.error));
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.description = message?.Message;
    this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
    this.modalModel.title = 'Não foi possível aceitar o Pré-cadastro!';
    this.modalModel.success = false;
    this.showModal = true;
  }

  refuse() {
    if (this.id) {
      this.sendingRefuse = true;
      this.enabledSending = false;
      this.enabledBack = false;
      this.operatorService.updateOperatorDisapprove(this.id).subscribe({
        next: this.refuseSuccess.bind(this),
        error: this.refuseError.bind(this),
      });
    }
  }

  refuseSuccess(res: any) {
    this.sendingRefuse = false;
    this.enabledSending = true;
    this.enabledBack = true;
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.description = 'Os dados da empresa foram descartados.';
    this.modalModel.imagePath = '../../../assets/icons/approve-modal.svg';
    this.modalModel.title = 'Pré-cadastro recusado!';
    this.modalModel.success = true;
    this.showModal = true;
  }

  refuseError(error: HttpErrorResponse) {
    this.sendingRefuse = false;
    this.enabledSending = true;
    this.enabledBack = true;
    const message = JSON.parse(JSON.stringify(error.error));
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.description = message?.Message;
    this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
    this.modalModel.title = 'Não foi possível recusar o Pré-cadastro!';
    this.modalModel.success = false;
    this.showModal = true;
  }
}
