import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/common/services/base.service';
import { CreateAnalystModel } from './models/create-analyst-model';
import { GetAnalystModel } from './models/get-analyst-model';
import { GetAnalystUserSystemModel } from './models/get-analyst-usersystem-model';
import { UpdateAnalystModel } from './models/update-analyst-model';
import { CryptoService } from 'src/app/services/Encryption/CryptoService';

@Injectable({
  providedIn: 'root',
})
export class AnalystService extends BaseService {
  constructor(private http: HttpClient, private cryptoService: CryptoService) {
    super('analysts');
  }

  getAnalysts() {
    return this.http.get<GetAnalystModel[]>(this.url);
  }

  getAnalystById(analystId: string) {
    const analystIdEncrypted = this.cryptoService.crypt(analystId);
    return this.http.get<GetAnalystModel>(`${this.url}/${analystIdEncrypted}`);
  }

  getAnalystByIdUserSystem(analystId: string) {
    const analystIdEncrypted = this.cryptoService.crypt(analystId);
    return this.http.get<GetAnalystUserSystemModel>(`${this.url}/usersystem/${analystIdEncrypted}`);
  }

  createAnalyst(request: CreateAnalystModel) {
    return this.http.post<GetAnalystModel>(this.url, request);
  }

  updateAnalyst(request: UpdateAnalystModel) {
    const analystIdEncrypted = this.cryptoService.crypt(request.analystId);
    return this.http.patch<GetAnalystModel>(`${this.url}/${analystIdEncrypted}`, request);
  }
}
