import { Buffer } from 'buffer';
import { AuthService } from 'src/app/common/services/auth.service';
import { ModalModel } from 'src/app/pages/pre-registration/models/modal.model';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ButtonsType } from 'src/app/shared/enums/Buttonstypes';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { InfoboxTypeEnum } from 'src/app/shared/infobox/enums/infobox-type.enum';
import { StorageService } from 'src/app/common/services/storage.service';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import { SideIconTypeEnum } from 'src/app/shared/input/enums/side-icon-type.enum';
import { AuthValidateTokenResponse } from '../../../common/models/auth-validateToken-response';
import { AuthResetPasswordModel } from '../../../common/models/auth-resetPassword.model';

@Component({
  selector: 'app-lost-password',
  templateUrl: './lost-password.component.html',
  styleUrls: ['./lost-password.component.scss'],
})
export class LostPasswordComponent implements OnInit {
  // Enums
  public eButtonsType = ButtonsType;
  public eButtonsColor = ButtonColorEnum;
  public eInputType = InputTypeEnum;
  public eInfoboxType = InfoboxTypeEnum;
  public eMaskType = MaskTypeEnum;
  public eSideIconType = SideIconTypeEnum;

  // Misc
  public modalModel: ModalModel = new ModalModel();
  public captchaSuccess: boolean = false;

  // Aux
  public resetPass: boolean = false;
  public tokenIsValid: boolean = true;
  public passIsValid: boolean = false;
  public enableButtonRequest: boolean = false;
  public enableButton: boolean = true;
  public loadingButton: boolean = false;

  // Data
  public name!: string;
  public username!: string;
  public token!: string;
  public passErrorMessage!: string;

  // Forms
  public form = this.formBuilder.group({
    user: ['', Validators.required],
    recaptcha: ['', Validators.required],
  });
  public formPass = this.formBuilder.group({
    password: ['', Validators.required],
    validatePassword: ['', Validators.required],
  });

  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public authService: AuthService,
    public storageService: StorageService,
  ) { }

  ngOnInit(): void {
    this.formPass.valueChanges.subscribe(() => {
      const validatePassword = this.formPass.controls.validatePassword.value;
      const password = this.formPass.controls.password.value;

      if (validatePassword === '') {
        this.passErrorMessage = 'Este campo é obrigatório';
      } else if (validatePassword !== password) {
        this.passErrorMessage = 'As senhas não coincidem';
        this.formPass.controls.validatePassword.setErrors({ unmatchpassword: true });
      } else {
        this.passErrorMessage = '';
        this.formPass.controls.validatePassword.setErrors(null);
      }

      if (this.formPass.controls.validatePassword.errors === null
          && this.formPass.controls.password.errors === null) {
        this.enableButton = true;
      } else {
        this.enableButton = false;
      }
    });

    this.form.valueChanges.subscribe(() => {
      if (this.form.controls.user.value !== ''
      && this.form.controls.recaptcha.value !== '') {
        this.enableButtonRequest = true;
      }
    });

    this.activatedRoute.paramMap.subscribe((params : ParamMap) => {
      const token = params.get('token') ?? '';
      if (token === '') {
        this.resetPass = false;
      } else {
        this.token = token;
        this.resetPass = true;
        const tokenCred = this.storageService.getTokenCredentials();
        if (!tokenCred) {
          this.authService.loginCredentials().subscribe({
            next: () => { this.validateToken(this.token); },
            error: this.handleloginCredentialsError.bind(this),
          });
        } else {
          this.validateToken(this.token);
        }
      }
    });
  }

  validateToken(token:string) {
    this.authService.validateToken(token).subscribe(
      {
        next: this.validateTokenSuccess.bind(this),
        error: this.validateTokenError.bind(this),
      },
    );
  }

  validateTokenSuccess(element: AuthValidateTokenResponse) {
    this.resetPass = true;
    this.enableButton = false;
    this.name = element.name;
    this.username = element.username;
  }

  validateTokenError() {
    this.tokenIsValid = false;
    this.resetPass = false;
  }

  clickButton() {
    const tokenCred = this.storageService.getTokenCredentials();
    if (!tokenCred) {
      this.authService.loginCredentials().subscribe({
        next: () => { this.sendRequest.bind(this); },
        error: this.handleloginCredentialsError.bind(this),
      });
    } else {
      this.sendRequest();
    }
  }

  sendRequest() {
    this.loadingButton = true;
    if (this.resetPass) {
      const resetPassModel : AuthResetPasswordModel = new AuthResetPasswordModel();
      const password: string = this.formPass.controls.password.value ?? '';

      if (this.formPass.controls.password.errors !== null
        || this.formPass.controls.validatePassword.errors !== null) {
        return;
      }

      resetPassModel.username = this.username;
      resetPassModel.token = this.token;
      resetPassModel.password = Buffer.from(password, 'utf8').toString('base64');

      this.authService.resetPassword(resetPassModel).subscribe({
        next: this.resetPasswordSuccess.bind(this),
        error: this.resetPasswordError.bind(this),
      });
    } else if (this.form.controls.recaptcha.value && this.form.controls.user.valid) {
      const username = this.form.controls.user.value ?? '';
      const recaptcha = this.form.controls.recaptcha.value ?? '';

      this.authService.requestResetPassword(username, recaptcha).subscribe({
        next: this.requestResetPasswordSuccess.bind(this),
        error: this.requestResetPasswordError.bind(this),
      });
    }
  }

  handleloginCredentialsError() {
    this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
    this.modalModel.title = 'Não foi possível iniciar a recuperação da senha';
    this.modalModel.description = 'Tente novamente mais tarde ou entre em contato com algum administrador.';
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.success = false;
    this.modalModel.showModal = true;
  }

  requestResetPasswordSuccess() {
    this.buildModal('ENTENDI', 'Caso o dado fornecido esteja correto, você receberá uma notificação no e-mail cadastrado com os detalhes de como prosseguir com o reset de senha. Se não recebê-lo em alguns minutos, verifique sua pasta de spam.', 'Reset de senha solicitado com sucesso!', true, true, '../../../assets/icons/approve-modal.svg');
  }
  requestResetPasswordError() {
    this.buildModal('ENTENDI', 'Ocorreu um erro ao solicitar a alteração da senha', 'Reset de Senha', true, false, '../../../../assets/icons/warning-600.gif');
  }

  resetPasswordSuccess() {
    this.buildModal('ENTENDI', 'Senha alterada com sucesso', 'Reset de Senha', true, true, '../../../../assets/icons/approve-modal.svg');
  }
  resetPasswordError() {
    this.buildModal('ENTENDI', 'Ocorreu um erro na alteração da senha', 'Reset de Senha', true, false, '../../../../assets/icons/warning-600.gif');
  }

  closeModal() {
    this.resetModal();
    if (this.modalModel.success) {
      this.router.navigate(['/login']);
    }
  }

  // Modal Building
  buildModal(btnLabel: string, desc: string, title: string, show: boolean, success: boolean, imagePath: string) {
    this.modalModel.buttonLabel = btnLabel;
    this.modalModel.description = desc;
    this.modalModel.showModal = show;
    this.modalModel.success = success;
    this.modalModel.title = title;
    this.modalModel.imagePath = imagePath;
    this.loadingButton = false;
  }

  resetModal() {
    this.modalModel.buttonLabel = '';
    this.modalModel.description = '';
    this.modalModel.showModal = false;
    this.modalModel.title = '';
    this.loadingButton = false;
  }

  onBack() {
    this.router.navigate(['/login']);
  }

  passIsValidOutput(event:boolean) {
    this.passIsValid = event;
  }
}
