import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { StorageService } from 'src/app/common/services/storage.service';
import { Utils } from 'src/app/common/utils/utils';
import { FixedCostService } from 'src/app/services/fixedCosts/fixed-costs.service';
import { GetFixedCostFilterModel } from 'src/app/services/fixedCosts/models/get-fixedCostFilter.model';
import { FixedCostModel } from 'src/app/services/fixedCosts/models/get-fixedCosts.model';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { StatusColorEnum } from 'src/app/shared/enums/StatusColor';
import { ModalModel } from '../../pre-registration/models/modal.model';
import * as FixcedCostActions from '../../../common/store/fixedCost/fixedCost.actions';
import { Store } from '@ngrx/store';
import {
  IFixedCostState,
  ILoadingState,
} from 'src/app/common/store/fixedCost/fixedCost.state';
import { map } from 'rxjs';

@Component({
  selector: 'app-fixed-costs-list',
  templateUrl: './fixed-costs-list.component.html',
  styleUrls: ['./fixed-costs-list.component.scss'],
})
export class FixedCostsListComponent implements OnInit {
  @Input() fixedCosts: FixedCostModel[] = [];

  public statusColorEnum = StatusColorEnum;
  public showSkeleton = false;
  public showNotFound = false;
  public loadingReport = false;
  public ebuttonColor = ButtonColorEnum;
  public filters: GetFixedCostFilterModel | undefined;
  public modalModel = new ModalModel();

  constructor(
    public util: Utils,
    private router: Router,
    private storageService: StorageService,
    public service: FixedCostService,
    private store: Store<{
      fixedCost: IFixedCostState;
      loadingFixedCost: ILoadingState;
    }>
  ) {}

  ngOnInit(): void {}

  formatDate(date: Date) {
    return moment(date).locale('pt-br').clone().format('MM/YYYY');
  }

  getStatusColor(status: string) {
    switch (status) {
      case 'Concluido':
        return this.statusColorEnum.success;
      default:
        return this.statusColorEnum.attention;
    }
  }

  getStatusIcon(status: string) {
    switch (status) {
      case 'Concluido':
        return '../../../../assets/icons/Success-green.svg';
      default:
        return '../../../../assets/icons/Timer Yellow Outline.svg';
    }
  }

  viewFixedCost(fixedCost: FixedCostModel) {
    this.store.dispatch(FixcedCostActions.clearFixedCost());
    this.router.navigate([`fixed-costs/${fixedCost.fixedCostId}`]);
  }

  getFixedCostReportDownload() {
    if (this.loadingReport) return;
    this.loadingReport = true;
    this.filters = this.storageService.getFixedCostFilterForm();
    if (this.filters === undefined) {
      this.filters = new GetFixedCostFilterModel();
    }
    this.service.getFixedCostsReportDownload(this.filters).subscribe({
      next: this.handleFixedCostsReportDownloadSuccess.bind(this),
      error: this.handleFixedCostsReportDownloadError.bind(this),
      complete: () => {
        this.loadingReport = false;
      },
    });
  }

  handleFixedCostsReportDownloadSuccess(reportFile: Blob) {
    const fileAnchor = document.createElement('a');
    const options = {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    } as Intl.DateTimeFormatOptions;
    fileAnchor.download = `RelatorioSolicitacoes-${new Intl.DateTimeFormat(
      'pt-BR',
      options
    ).format(new Date())}.xlsx`;
    fileAnchor.href = window.URL.createObjectURL(reportFile);
    fileAnchor.click();
    fileAnchor.hidden = true;
    window.URL.revokeObjectURL(fileAnchor.href);
    this.loadingReport = false;
  }
  handleFixedCostsReportDownloadError(res: HttpErrorResponse) {
    const message = JSON.parse(JSON.stringify(res.error));
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.description =
      'Tente novamente mais tarde ou entre em contato com algum administrador.';
    this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
    this.modalModel.title = `Não foi possível realizar o download do relatório. ${message?.Message}`;
    this.modalModel.success = false;
    this.modalModel.showModal = true;
    this.loadingReport = false;
  }
  clickModal() {
    this.modalModel.showModal = false;
  }
}
