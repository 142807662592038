import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GetAnalystModel } from 'src/app/services/analyst/models/get-analyst-model';
import { CryptoService } from 'src/app/services/Encryption/CryptoService';
import { StatusColorEnum } from 'src/app/shared/enums/StatusColor';

@Component({
  selector: 'app-analyst-list-item',
  templateUrl: './analyst-list-item.component.html',
  styleUrls: ['./analyst-list-item.component.scss'],
})
export class AnalystListItemComponent implements OnInit {
  @Input() analyst!: GetAnalystModel;

  constructor(
    private router: Router,
    private cryptoService: CryptoService,
  ) { }

  public StatusColorEnum = StatusColorEnum;

  ngOnInit(): void {}

  handleDataClick(id: string) {
    var idCrypto = this.cryptoService.crypt(id);
    this.router.navigate(['/admin/analyst/', idCrypto]);
  }
}
