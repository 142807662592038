import { Injectable } from '@angular/core';
import { AES, enc, mode, pad } from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CryptoService {
  private readonly USER_SESSION_ID_KEY = `${environment.AppName}US`;

  public crypt(data: string) {
    const encryptionKey = window.localStorage.getItem(this.USER_SESSION_ID_KEY) ?? '';
    const key = enc.Hex.parse(encryptionKey);
    const iv = enc.Hex.parse(encryptionKey.substring(0, 32));

    const encrypted = AES.encrypt(data, key, {
      iv: iv,
      mode: mode.CBC,
      padding: pad.Pkcs7
    });

    const base64Txt = btoa(encrypted.toString());

    return base64Txt;
  }

  public decrypt(textToBeDecrypted: string) {
    const encryptionKey = window.localStorage.getItem(this.USER_SESSION_ID_KEY) ?? '';
    const key = enc.Hex.parse(encryptionKey);
    const iv = enc.Hex.parse(encryptionKey.substring(0, 32));

    const decryptedBase64 = atob(textToBeDecrypted);
    const decrypted = AES.decrypt(decryptedBase64, key, {
      iv: iv,
      mode: mode.CBC,
      padding: pad.Pkcs7
    });

    return decrypted.toString(enc.Utf8);
  }
}
