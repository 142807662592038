<div class="content">
  <app-infobox [type]="InfoboxTypeEnum.attention">
    <div content>
      <span>É necessario se cadastrar também na plataforma</span>
      <app-buttons
        label="G-Certifica"
        icon="../../../../assets/icons/arrow-slate100.svg"
        [type]="eButtonsType.navigate"
        [enabled]="true"
        [right]="true"
        (clickButton)="onGCertifica()"
      ></app-buttons>
    </div>
  </app-infobox>
  <app-lgpd-info></app-lgpd-info>
  <form>
    <div class="input-box">
      <app-input
        label="CEP"
        [type]="eInputType.text"
        [errorMessage]="error ? errorMessage : 'Este campo é obrigatório'"
        [control]="form.controls.cep"
        [mask]="eInputMask.cep"
        [search]="true"
        [loading]="loading"
        [searchError]="error"
        [disabled]="disabled"
        (focusout)="searchAddress(true)"
        (searchClick)="searchAddress($event)"
      ></app-input>
      <app-select
        field="Estado"
        errorMessage="Este campo é obrigatório"
        [singleControl]="form.controls.uf"
        [selected]="stateSelected"
        [options]="states"
      ></app-select>
      <app-input
        label="Cidade"
        errorMessage="Este campo é obrigatório"
        [control]="form.controls.city"
        [type]="eInputType.text"
        [maxlength]="50"
      ></app-input>
      <app-input
        label="Bairro"
        errorMessage="Este campo é obrigatório"
        [control]="form.controls.neighborhood"
        [type]="eInputType.text"
        [maxlength]="50"
      ></app-input>
    </div>
    <app-input
      label="Endereço"
      errorMessage="Este campo é obrigatório"
      [control]="form.controls.address"
      [type]="eInputType.text"
      [maxlength]="50"
    ></app-input>
    <div class="input-box">
      <app-input
        label="Número"
        errorMessage="Este campo é obrigatório"
        [control]="form.controls.number"
        [type]="eInputType.text"
        [maxlength]="10"
      ></app-input>
      <app-input
        label="Complemento"
        description="(opcional)"
        [control]="form.controls.complement"
        [type]="eInputType.text"
        [maxlength]="20"
      ></app-input>
    </div>
  </form>
</div>
