/* eslint-disable max-len */
/* eslint-disable no-return-assign */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { pmt } from 'financial';
import moment from 'moment';
import { Subject, Subscription, map } from 'rxjs';
import { StorageService } from 'src/app/common/services/storage.service';
import {
  IDfdvState,
  ILoadingState,
} from 'src/app/common/store/dfdv/dfdv.state';
import { DfdvUtils } from 'src/app/common/utils/dfdv-utils';
import { ModalModel } from 'src/app/pages/pre-registration/models/modal.model';
import { DfdvService } from 'src/app/services/dfdv/dfdv.service';
import { FleetConfigurationItemsModel } from 'src/app/services/dfdv/fleet/models/fleet-configuration-items.model';
import { FleetModel } from 'src/app/services/dfdv/fleet/models/fleet.model';
import { GetDfdvModel } from 'src/app/services/dfdv/models/getDfdv.model';
import { FleetTableRowStatusEnum } from 'src/app/shared/fleet-table/enums/fleet-table.enum';
import * as DfdvActions from '../../../../common/store/dfdv/dfdv.actions';
import { FleetCapitalModel } from '../../../../services/dfdv/fleet/models/fleet-capital.model';
import { FleetProfitModel } from '../../../../services/dfdv/fleet/models/fleet-profit.model';
import { FleetTaxModel } from '../../../../services/dfdv/fleet/models/fleet-tax.model';
import { FleetTractorModel } from '../../../../services/dfdv/fleet/models/fleet-tractor.model';
import { FleetTrailerModel } from '../../../../services/dfdv/fleet/models/fleet-trailer.model';
import { FleetModalNewConfigModel } from './fleet-modal-new-config/models/fleet-new-config.model';
import { FleetSpecificationModel } from './fleet-specifications/models/fleet-specifications.model';

@Component({
  selector: 'app-fleet',
  templateUrl: './fleet.component.html',
  styleUrls: ['./fleet.component.scss'],
})
export class FleetComponent implements OnInit, OnDestroy {
  public shouldDisableFields: boolean = false;
  public isLoading: boolean = false;
  public showNewConfigModal: boolean = false;
  public loadingButton: boolean = false;
  public modalModel: ModalModel = new ModalModel();
  public dfdv!: GetDfdvModel;
  public dfdvUtils!: DfdvUtils;
  public activeActions: boolean = false;

  public specificationData: FleetSpecificationModel =
    new FleetSpecificationModel();
  public configurationItem: FleetConfigurationItemsModel[] = [];

  public rowStatus: FleetTableRowStatusEnum[] = [];

  public tractorList!: FleetTractorModel[];
  public trailerList!: FleetTrailerModel[];
  public taxList!: FleetTaxModel[];
  public capitalList!: FleetCapitalModel[];
  public profitList!: FleetProfitModel[];
  public update: boolean = false;

  public newConfigModel!: FleetModalNewConfigModel;

  private destroy$ = new Subject<void>();
  public unSubscribe!: Subscription;

  dfdvStore$ = this.store.select('dfdv').pipe(map((x) => x.dfdv));
  loadingStore$ = this.store.select('loading').pipe((x) => x);

  constructor(
    public storage: StorageService,
    public service: DfdvService,
    public store: Store<{ dfdv: IDfdvState; loading: ILoadingState }>
  ) {}

  ngOnInit(): void {
    this.unSubscribe = this.dfdvStore$.subscribe((dfdv) => {
      this.dfdv = { ...dfdv };

      this.dfdvUtils = new DfdvUtils(
        this.dfdv,
        this.storage.getRole(),
        this.store
      );
      if (!this.update && this.dfdvUtils.isEnableToEdit()) {
        this.update = this.dfdvUtils.updateFleetDfdv();
      }
      this.verifyPermissions();
      this.buildSpecification();
      this.buildCards();
    });
    this.loadingStore$.subscribe((loading) => {
      this.isLoading = loading.loading;
    });
  }

  ngOnDestroy() {
    this.unSubscribe.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  verifyPermissions() {
    const status = this.dfdvUtils.dfdvStatus;

    if (status) {
      const enableForOPL =
        !this.dfdvUtils.isUserRaizen() && this.dfdvUtils.isPreliminary();
      const enableForRaizen =
        this.dfdvUtils.isUserRaizen() &&
        (this.dfdvUtils.isDraft() || this.dfdvUtils.isNew());

      this.activeActions = enableForOPL || enableForRaizen;
      this.shouldDisableFields = !(enableForOPL || enableForRaizen);
      return;
    }

    this.activeActions = false;
  }

  buildSpecification() {
    if (!this.dfdv.scope) return;

    this.specificationData.totalQuantityTractor =
      this.dfdv.scope.equipment.tractorQuantity;
    this.specificationData.totalQuantityTrailer =
      this.dfdv.scope.equipment.trailerQuantity;
    this.specificationData.factorTrailer =
      this.dfdv.scope.equipment.jointFactor;
    this.specificationData.costTractor = this.dfdv.scope.equipment.tractorCost;
    this.specificationData.costTrailer = this.dfdv.scope.equipment.trailerCost;
    this.specificationData.quantityTiresPerTractor =
      this.dfdv.scope.equipment.tractorTireQuantity;
    this.specificationData.quantityTiresPerTrailer =
      this.dfdv.scope.equipment.trailerTireQuantity;
    this.specificationData.lifeCycleTractor =
      this.dfdv.scope.equipment.tractorLifespan;
    this.specificationData.lifeCycleTrailer =
      this.dfdv.scope.equipment.trailerLifespan;
    this.specificationData.capitalRemunerationRate =
      this.dfdv.scope.fee.capitalRemuneration;
  }

  buildCards() {
    if (!this.dfdv.fleet) return;
    this.rowStatus = [];

    this.tractorList = this.dfdv.fleet.fleetConfigurationItems.map(
      (x) => x.tractor
    );
    this.tractorList.forEach((x) =>
      this.rowStatus.push(
        x.active
          ? FleetTableRowStatusEnum.ACTIVE
          : FleetTableRowStatusEnum.STOPED
      )
    );
    this.trailerList = this.dfdv.fleet.fleetConfigurationItems.map(
      (x) => x.trailer
    );
    this.taxList = this.dfdv.fleet.fleetConfigurationItems.map((x) => x.tax);
    this.capitalList = this.dfdv.fleet.fleetConfigurationItems.map(
      (x) => x.capitalRemunerationAndDepreciation
    );
    this.profitList = this.dfdv.fleet.fleetConfigurationItems.map(
      (x) => x.profit
    );
  }

  handleOpenConfigModal(index: any) {
    if (index !== undefined && index >= 0) {
      this.newConfigModel = new FleetModalNewConfigModel();
      this.newConfigModel.index = index;
      this.newConfigModel.active =
        this.dfdv.fleet.fleetConfigurationItems[index].tractor.active;
      this.newConfigModel.tractorQuantity =
        this.dfdv.fleet.fleetConfigurationItems[
          index
        ].tractor.quantity.toString();
      this.newConfigModel.tractorFinancingStatus =
        this.dfdv.fleet.fleetConfigurationItems[
          index
        ].tractor.tractorFinancingStatus;
      this.newConfigModel.tractorManufacturer =
        this.dfdv.fleet.fleetConfigurationItems[index].tractor.tractorBrand;
      this.newConfigModel.tractorModel =
        this.dfdv.fleet.fleetConfigurationItems[index].tractor.tractorModel;
      this.newConfigModel.tractorYear =
        this.dfdv.fleet.fleetConfigurationItems[
          index
        ].tractor.tractorYear.toString();
      (this.newConfigModel.tractorMonthYearEntry = moment(
        this.dfdv.fleet.fleetConfigurationItems[index].tractor
          .tractorAcquisitionDate
      )
        .locale('pt-br')
        .format('MMMM/YYYY')
        .toUpperCase()),
        (this.newConfigModel.tractorResidual =
          this.dfdv.fleet.fleetConfigurationItems[
            index
          ].tractor.tractorResidual.toString());
      this.newConfigModel.trailerFinancingStatus =
        this.dfdv.fleet.fleetConfigurationItems[
          index
        ].trailer.trailerFinancingStatus;
      this.newConfigModel.trailerImplement =
        this.dfdv.fleet.fleetConfigurationItems[index].trailer.trailerImplement;
      this.newConfigModel.trailerMaterial =
        this.dfdv.fleet.fleetConfigurationItems[index].trailer.trailerMaterial;
      this.newConfigModel.trailerType =
        this.dfdv.fleet.fleetConfigurationItems[index].trailer.trailerType;
      this.newConfigModel.trailerYear =
        this.dfdv.fleet.fleetConfigurationItems[
          index
        ].trailer.trailerYear.toString();
      (this.newConfigModel.trailerMonthYearEntry = moment(
        this.dfdv.fleet.fleetConfigurationItems[index].trailer
          .trailerAcquisitionDate
      )
        .locale('pt-br')
        .format('MMMM/YYYY')
        .toUpperCase()),
        (this.newConfigModel.trailerResidual =
          this.dfdv.fleet.fleetConfigurationItems[
            index
          ].trailer.trailerResidual.toString());
    } else {
      this.newConfigModel = new FleetModalNewConfigModel();
    }
    this.showNewConfigModal = true;
  }

  handleAddOrUpdateConfig(config: FleetModalNewConfigModel) {
    // Tractor
    const tractor = new FleetTractorModel();
    tractor.active = config.active;
    tractor.quantity = +config.tractorQuantity;
    tractor.tractorFinancingStatus = config.tractorFinancingStatus;
    tractor.tractorBrand = config.tractorManufacturer;
    tractor.tractorModel = config.tractorModel;
    tractor.capitalRemuneration =
      config.tractorFinancingStatus === 'Finame'
        ? this.dfdv.scope.fee.capitalRemuneration
        : 0;
    (tractor.tractorCost = this.dfdv.scope.equipment.tractorCost),
      (tractor.tractorTireCost =
        this.dfdv.cvTire.tireCost *
        this.dfdv.scope.equipment.tractorTireQuantity);
    tractor.tractorPisCofinsCost =
      (tractor.tractorCost * (this.dfdv.tax.pis + this.dfdv.tax.cofins)) / 100;
    tractor.tractorIcmsCost = this.dfdv.tax.isCreditWithoutAssetAllowed
      ? (tractor.tractorCost * this.dfdv.tax.icms) / 100
      : 0;
    tractor.tractorNetValueDepreciation =
      tractor.tractorCost === 0
        ? 0
        : tractor.tractorCost -
          tractor.tractorTireCost -
          tractor.tractorPisCofinsCost -
          tractor.tractorIcmsCost;

    tractor.tractorNetValueRemuneration =
      tractor.tractorCost === 0
        ? 0
        : this.dfdv.tax.hasPresumedIcmsCredit
        ? tractor.tractorCost - tractor.tractorPisCofinsCost
        : tractor.tractorCost -
          tractor.tractorPisCofinsCost -
          tractor.tractorIcmsCost;

    tractor.tractorYear = +config.tractorYear;
    tractor.tractorAcquisitionDate = moment(
      config.tractorMonthYearEntry
    ).toDate();
    tractor.tractorDepreciationPeriod =
      72 - 12 * (moment().year() - tractor.tractorYear);
    tractor.tractorUsageCycle = 12 * this.dfdv.scope.equipment.tractorLifespan;
    tractor.tractorResidual = +config.tractorResidual;

    // Trailer
    const trailer = new FleetTrailerModel();
    trailer.trailerFinancingStatus = config.trailerFinancingStatus;
    trailer.trailerImplement = config.trailerImplement;
    trailer.trailerMaterial = config.trailerMaterial;
    trailer.trailerType = config.trailerType;
    trailer.trailerCost =
      config.trailerFinancingStatus === 'Raízen'
        ? 0
        : this.dfdv.scope.equipment.trailerCost;
    trailer.trailerTireCost =
      this.dfdv.cvTire.tireCost * this.dfdv.scope.equipment.trailerTireQuantity;
    trailer.trailerBaseProfit =
      config.trailerFinancingStatus === 'Quitado' ? 0 : trailer.trailerCost;
    trailer.trailerPisCofinsCost =
      config.trailerFinancingStatus === 'Quitado'
        ? 0
        : (trailer.trailerCost * (this.dfdv.tax.pis + this.dfdv.tax.cofins)) /
          100;
    trailer.trailerIcmsCost = this.dfdv.tax.isCreditWithoutAssetAllowed
      ? (trailer.trailerCost * this.dfdv.tax.icmsWithoutComplement) / 100
      : 0;

    trailer.trailerNetValueDepreciation =
      trailer.trailerCost === 0
        ? 0
        : this.dfdv.tax.hasPresumedIcmsCredit
        ? trailer.trailerCost - trailer.trailerPisCofinsCost
        : trailer.trailerCost -
          trailer.trailerTireCost -
          trailer.trailerPisCofinsCost -
          trailer.trailerIcmsCost;

    trailer.trailerNetValueRemuneration =
      trailer.trailerCost === 0
        ? 0
        : this.dfdv.tax.hasPresumedIcmsCredit
        ? trailer.trailerCost - trailer.trailerPisCofinsCost
        : trailer.trailerCost -
          trailer.trailerPisCofinsCost -
          trailer.trailerIcmsCost;

    trailer.trailerYear = +config.trailerYear;
    trailer.trailerAcquisitionDate = moment(
      config.trailerMonthYearEntry
    ).toDate();
    trailer.trailerDepreciationPeriod =
      108 - 12 * (moment().year() - trailer.trailerYear);
    trailer.trailerUsageCycle = 12 * this.dfdv.scope.equipment.trailerLifespan;
    trailer.trailerResidual = +config.trailerResidual;

    // Tax
    const tax = new FleetTaxModel();
    tax.dpvat = this.dfdv.scope.fee.dpvat / 12;
    tax.ipvaAndLicensingFee = this.dfdv.scope.fee.hasIpvaAndLicensingFee
      ? (322442 *
          (this.dfdv.scope.fee.ipvaAndLicensingFee / 100) *
          (1 - this.dfdv.scope.fee.ipvaWithDiscount / 100)) /
        12
      : (322442 * this.dfdv.scope.fee.ipvaAndLicensingFee) / 100 / 12;
    tax.autoInsurance =
      (this.dfdv.scope.fee.mandatoryInsurance +
        this.dfdv.scope.fee.crlvEmissionFee) /
      12;
    tax.rcfv = this.dfdv.scope.fee.rcfv / 12;
    tax.licensing = tractor.active
      ? (this.dfdv.scope.fee.licensing / 12) *
        (1 + this.dfdv.scope.equipment.jointFactor)
      : 0;

    // capital
    const capital = new FleetCapitalModel();
    capital.tractorDepreciation =
      tractor.tractorDepreciationPeriod === 0 || tractor.tractorUsageCycle === 0
        ? 0
        : (tractor.tractorNetValueDepreciation *
            (1 - tractor.tractorResidual / 100)) /
          tractor.tractorUsageCycle;
    capital.trailerDepreciation =
      trailer.trailerDepreciationPeriod === 0 ||
      trailer.trailerFinancingStatus === 'Raízen' ||
      trailer.trailerUsageCycle === 0 ||
      this.dfdv.scope.equipment.jointFactor === 0
        ? 0
        : ((trailer.trailerNetValueDepreciation *
            (1 - trailer.trailerResidual / 100)) /
            trailer.trailerUsageCycle) *
          this.dfdv.scope.equipment.jointFactor;
    capital.tractorCapitalRemuneration =
      -pmt(
        (1 + tractor.capitalRemuneration / 100) ** (1 / 12) - 1,
        tractor.tractorDepreciationPeriod,
        tractor.tractorNetValueRemuneration -
          (tractor.tractorNetValueDepreciation * tractor.tractorResidual) / 100
      ) - capital.tractorDepreciation;
    capital.trailerCapitalRemuneration =
      -pmt(
        (1 + tractor.capitalRemuneration / 100) ** (1 / 12) - 1,
        trailer.trailerDepreciationPeriod,
        this.dfdv.scope.equipment.jointFactor *
          (trailer.trailerNetValueRemuneration -
            (trailer.trailerNetValueDepreciation * trailer.trailerResidual) /
              100)
      ) - capital.trailerDepreciation;
    capital.tractorCapitalRemunerationOnTaxes =
      tractor.tractorDepreciationPeriod === 0 ||
      this.dfdv.tax.hasPresumedIcmsCredit
        ? 0
        : ((2 +
            (tractor.tractorDepreciationPeriod / 12 - 1) *
              (1 + tractor.tractorResidual / 100)) /
            ((24 * tractor.tractorDepreciationPeriod) / 12)) *
          (tractor.capitalRemuneration / 100) *
          (tractor.tractorPisCofinsCost + tractor.tractorIcmsCost);
    capital.trailerCapitalRemunerationOnTaxes =
      tractor.tractorDepreciationPeriod === 0 ||
      this.dfdv.tax.hasPresumedIcmsCredit
        ? 0
        : ((1 + trailer.trailerResidual / 100) /
            ((24 * trailer.trailerDepreciationPeriod) / 12)) *
          (tractor.capitalRemuneration / 100) *
          trailer.trailerIcmsCost *
          this.dfdv.scope.equipment.jointFactor;
    capital.capitalRemunerationOnTaxes =
      tractor.tractorNetValueRemuneration + trailer.trailerNetValueRemuneration;
    capital.totalRemuneration =
      capital.tractorDepreciation +
      capital.trailerDepreciation +
      capital.tractorCapitalRemuneration +
      capital.trailerCapitalRemuneration +
      capital.tractorCapitalRemunerationOnTaxes +
      capital.trailerCapitalRemunerationOnTaxes;

    // profit
    const profit = new FleetProfitModel();
    profit.totalProfit = config.active
      ? (this.dfdv.profit.profitValue / 100) *
        (tractor.tractorCost +
          trailer.trailerCost * this.dfdv.scope.equipment.jointFactor)
      : 0;
    profit.fixedProfit =
      !config.active || config.tractorFinancingStatus === 'Quitado'
        ? 0
        : (this.dfdv.profit.totalFixedProfit / 100) *
          (tractor.tractorCost +
            trailer.trailerCost * this.dfdv.scope.equipment.jointFactor);
    profit.variableProfit = config.active
      ? profit.totalProfit - profit.fixedProfit
      : 0;

    const fleet: FleetModel = new FleetModel();
    fleet.tractorEstimatedMonthlyKm = this.dfdv.fleet.tractorEstimatedMonthlyKm;
    fleet.anpDieselWithArlaCost = this.dfdv.fleet.anpDieselWithArlaCost;
    fleet.averageTractorConsumption = this.dfdv.fleet.averageTractorConsumption;
    fleet.averageTractorMaintenance = this.dfdv.fleet.averageTractorMaintenance;
    fleet.averageTrailerMaintenance = this.dfdv.fleet.averageTrailerMaintenance;
    fleet.averageTireUnitCostPerKm = this.dfdv.fleet.averageTireUnitCostPerKm;
    fleet.averageWashingCost = this.dfdv.fleet.averageWashingCost;
    fleet.active = this.dfdv.fleet.active;
    fleet.stopped = this.dfdv.fleet.stopped;

    const fleetConfigurationItems: FleetConfigurationItemsModel[] = [];
    this.dfdv.fleet.fleetConfigurationItems.forEach((element, index) => {
      if (index !== config.index) {
        fleetConfigurationItems.push(element);
      } else {
        const configurationItens = new FleetConfigurationItemsModel();
        configurationItens.tractor = tractor;
        configurationItens.trailer = trailer;
        configurationItens.tax = tax;
        configurationItens.capitalRemunerationAndDepreciation = capital;
        configurationItens.profit = profit;
        fleetConfigurationItems.push(configurationItens);
      }
    });

    if (config.index === undefined) {
      const configurationItens = new FleetConfigurationItemsModel();
      configurationItens.tractor = tractor;
      configurationItens.trailer = trailer;
      configurationItens.tax = tax;
      configurationItens.capitalRemunerationAndDepreciation = capital;
      configurationItens.profit = profit;
      fleetConfigurationItems.push(configurationItens);
    }

    fleet.fleetConfigurationItems = fleetConfigurationItems;

    this.store.dispatch(
      DfdvActions.updateSubobject({
        subObjectName: 'fleet',
        data: fleet,
      })
    );

    this.dfdvUtils.updateFleetDfdv();

    this.showNewConfigModal = false;
    this.rowStatus = [];
    this.tractorList.forEach((x) =>
      this.rowStatus.push(
        x.active
          ? FleetTableRowStatusEnum.ACTIVE
          : FleetTableRowStatusEnum.STOPED
      )
    );
  }

  handleDeleteConfig(index: number) {
    const fleet: FleetModel = new FleetModel();
    const fleetConfigurationItems: FleetConfigurationItemsModel[] = [];
    this.dfdv.fleet.fleetConfigurationItems.forEach((element, i) => {
      if (i !== index) {
        fleetConfigurationItems.push(element);
      }
    });

    fleet.fleetConfigurationItems = fleetConfigurationItems;

    this.store.dispatch(
      DfdvActions.updateSubobject({
        subObjectName: 'fleet',
        data: fleet,
      })
    );

    this.showNewConfigModal = false;
    this.rowStatus = [];
    this.tractorList.forEach((x) =>
      this.rowStatus.push(
        x.active
          ? FleetTableRowStatusEnum.ACTIVE
          : FleetTableRowStatusEnum.STOPED
      )
    );
  }

  handleCloseConfigModal() {
    this.showNewConfigModal = false;
  }
}
