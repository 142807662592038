import {
  Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import moment from 'moment';
import { DatepickerIntervalModel } from './models/datepicker-interval.model';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})

export class DatepickerComponent implements OnInit {
  public rangeDate: string = '';
  public monthStart: string = '';
  public monthEnd: string = '';

  @Input() textData = new FormControl('');
  @Input() controlStartDate = new FormControl('');
  @Input() controlEndDate = new FormControl('');
  @Input() error: boolean = false;
  @Input() errorMessage: string = '';
  @Input() interval:boolean = false;
  @Input() disabled: boolean = false;
  @Input() monthCalendar: boolean = false;

  @Input() label: string = '';
  @Input() placeholder: string = '';

  @Output() updateValue: EventEmitter<boolean> = new EventEmitter();

  left: number | undefined;
  onFocus: boolean = false;

  ngOnInit() : void {
    if (this.interval && this.controlStartDate && this.controlEndDate) {
      this.dateFormat();
    } else {
      this.singleDateFormat();
    }

    this.controlStartDate.valueChanges.subscribe((x) => {
      if (x && !this.interval) {
        this.singleDateFormat();
      }
    });
  }

  resetDate() {
    this.controlStartDate.reset();
    this.controlStartDate.patchValue('');
    this.controlEndDate.reset();
    this.controlEndDate.patchValue('');
    this.updateValue.emit();
  }

  focusInput() {
    this.onFocus = true;
  }

  blurInput() {
    this.onFocus = false;
    if (this.interval && this.controlEndDate.value === '') {
      this.resetDate();
    }
  }

  onDateInterval(event:DatepickerIntervalModel) {
    this.controlStartDate.patchValue(event.startDate);
    this.controlEndDate.patchValue(event.endDate);
    this.controlStartDate.markAsDirty();
    this.controlEndDate.markAsDirty();

    if (this.interval) {
      if (this.controlStartDate.value !== '' && this.controlEndDate.value !== '') {
        this.blurInput();
        if (this.monthCalendar) {
          this.monthDateFormat();
        } else {
          this.dateFormat();
        }
      }
    } else if (this.controlStartDate.value !== '') {
      this.blurInput();
      this.singleDateFormat();
    }
  }

  dateFormat() {
    let text: string;
    text = 'De: ';
    text += this.controlStartDate.value;
    text += ' - Ate: ';
    text += this.controlEndDate.value;
    this.rangeDate = text;
    this.updateValue.emit();
  }

  monthDateFormat() {
    moment().locale('pt-br');
    const startDate = moment(this.controlStartDate.value).locale('pt-br').clone().format('MMMM YYYY')
      .toString()
      .toLocaleUpperCase();
    const endDate = moment(this.controlEndDate.value).locale('pt-br').clone().format('MMMM YYYY')
      .toString()
      .toLocaleUpperCase();

    this.controlStartDate.patchValue(
      moment(this.controlStartDate.value).locale('pt-br').clone().format('DD/MM/YYYY')
        .toString(),
    );
    this.controlEndDate.patchValue(
      moment(this.controlEndDate.value).locale('pt-br').clone().format('DD/MM/YYYY')
        .toString(),
    );

    if (startDate === endDate) {
      this.rangeDate = startDate;
    } else {
      let text: string;
      text = 'De: ';
      text += startDate;
      text += ' - Ate: ';
      text += endDate;
      this.rangeDate = text;
    }
  }

  singleDateFormat() {
    if (this.monthCalendar) {
      moment().locale('pt-br');
      const startDate = moment(this.controlStartDate.value).locale('pt-br').clone().format('MMMM YYYY')
        .toString()
        .toLocaleUpperCase();

      let text: string;
      text = '';
      text += startDate;
      this.rangeDate = text;
    } else {
      let text: string;
      text = '';
      text += this.controlStartDate.value;
      this.rangeDate = text;
    }

    this.updateValue.emit();
  }
}
