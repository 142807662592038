import { forkJoin, of } from 'rxjs';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import moment from 'moment';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { RoleModel } from 'src/app/common/models/role.model';
import { StorageService } from 'src/app/common/services/storage.service';
import { SolicitationService } from 'src/app/services/solicitation/solicitation.service';
import { GetSolicitationModel } from 'src/app/services/solicitation/models/get-solicitation-model';
import { UpdateSolicitationModel } from 'src/app/services/solicitation/models/update-solicitation-model';
import { OperatorService } from 'src/app/services/operator/operator.service';
import { GetOperatorModel } from 'src/app/services/operator/models/get-operator-model';
import { CreateSolicitationModel } from 'src/app/services/solicitation/models/create-solicitation-model';
import { GetPaymentTypeModel } from 'src/app/services/solicitation/models/get-payment-type-model';
import { UnitService } from 'src/app/services/unit/unit.service';
import { GetUnitModel } from 'src/app/services/unit/models/get-unit-model';
import { GetUnitCategoryModel } from 'src/app/services/unit/models/get-unit-category-model';
import { AuthService } from 'src/app/common/services/auth.service';
import { SideIconTypeEnum } from 'src/app/shared/input/enums/side-icon-type.enum';
import { GetSolicitationTypeModel } from '../../../../services/solicitation/models/get-SolicitationType-model';
import { GetAttachmentModel } from '../../../../services/solicitation/models/get-attachment-model';
import { AttachComponent } from '../../../../shared/attach/attach.component';
import { RolePermissionModel } from './models/role-permission.model';
import { ButtonsType } from '../../../../shared/enums/Buttonstypes';
import { MaskTypeEnum } from '../../../../shared/input/enums/mask-type.enum';
import { SolicitationJustify } from '../../solicitation-approval-modal/model/solicitation-justify.model';
import { ModalModel } from '../../../pre-registration/models/modal.model';
import { SolicitationStatusEnum } from '../../enums/solicitation-status.enum';

@Component({
  selector: 'app-solicitation-new',
  templateUrl: './solicitation-new.component.html',
  styleUrls: ['./solicitation-new.component.scss'],
})
export class SolicitationNewComponent implements OnInit {
  public form = this.formBuilder.group({
    data: [''],
    status: [''],
    opl: [''],
    unit: [''],
    category: [''],
    subcategory: [''],
    paymentType: [''],
    value: [''],
    negotiatedValue: [''],
    title: [''],
    description: [''],
    attachments: [[] as File[]],
    solicitationType: [''],
    quantity: [''],
    unitaryValue: [''],
    createBy: [''],
    recurrencyMonths: [''],
  });

  @ViewChild(AttachComponent) fileInput!: AttachComponent;

  public title = 'Nova Solicitação';
  public opls: string[] = [];
  public units: string[] = [];
  public categories: string[] = [];
  public subcategories: string[] = [];
  public paymentTypes: string[] = [];
  public role!: RoleModel;
  public visible: boolean = true;
  public showSubcategory: boolean = false;
  public fantasyName!: string;
  public selectedFiles: GetAttachmentModel[] = [];
  public totalSize: number = 0;
  public isAnalyst!: boolean;
  public showRecurrencyMonth: boolean = true;
  public types: string[] = [];
  public typeValues: string[] = [];
  public isOutrosCustos: boolean = true;
  public isLoading: boolean = true;
  public canValueReview: boolean = false;
  public buttonColor!: ButtonColorEnum;

  public uploadProgress: string = '0';
  public uploadInProgress: boolean = false;
  public showProgressValue: boolean = false;

  public username: string = '';
  public analystRole: string = '';
  public rolePermission: RolePermissionModel = new RolePermissionModel();

  public eButtonsType = ButtonsType;
  public eMaskTypeEnum = MaskTypeEnum;

  public solicitation = new GetSolicitationModel();
  public id: string | null = null;
  public loading: boolean = false;
  public sending: boolean = false;
  public enabledApprove: boolean = true;
  public enabledDisapprove: boolean = true;
  public enabledValueReview: boolean = true;
  public enabledBack: boolean = true;
  public sendButtons: boolean = false;
  public approveButtons: boolean = false;
  public hide: boolean = false;
  public loadingAttach!: boolean;
  public alreadyLoadFromStorage: boolean = false;

  public showModal: boolean = false;
  public approval: boolean = true;
  public sendApprovalValueReview: boolean = false;
  public sendApproval: boolean = false;
  public modalTitle: string = '';
  public buttonLabel: string = '';
  public buttonImage: string = '';
  public buttonType!: ButtonsType;
  public unitValues: string[] = [];
  public subCategoryValues: string[] = [];
  public oplValues: string[] = [];
  public categoryValues: string[] = [];
  public paymentTypeValues: string[] = [];
  public uploadSuccess: boolean = false;
  public recurrencySolicitation: boolean = false;
  public minRecurrencyMonths: number = 0;
  public maxRecurrencyMonths: number = 12;

  public unitsResponse: GetUnitModel[] = [];
  public modalModel = new ModalModel();

  public isRequiredJustify: boolean = true;

  public eSideIconType = SideIconTypeEnum;
  public get ButtonColorEnum() {
    return ButtonColorEnum;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public formBuilder: FormBuilder,
    public storageService: StorageService,
    private solicitationService: SolicitationService,
    private operatorService: OperatorService,
    private unitService: UnitService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.getUser();
    this.getUserRole();
    this.analystRole = this.getLoggedUserData();
    this.setRequiredJustify();

    this.route.paramMap.subscribe((params) => {
      this.id = params.get('id');

      if (this.id) {
        this.loadDataWithId();
        this.alreadyLoadFromStorage = true;
        this.loading = true;
      } else {
        this.loadData();
        this.checkIfAllRequestsHaveBeenCompleted();
        this.form.valueChanges.subscribe(() => this.setFormValuesInStorage());
      }
    });

    this.form.controls.solicitationType.registerOnChange(() => {
      if (this.form.controls.solicitationType.value === 'Outros Custos') {
        this.isOutrosCustos = true;
      } else {
        this.isOutrosCustos = false;
      }

      if (!this.id) {
        this.form.controls.quantity.patchValue('');
        this.form.controls.unitaryValue.patchValue('');
      }
    });

    this.form.controls.quantity.valueChanges.subscribe(() => {
      if (!this.isOutrosCustos) {
        const unitaryValue = this.form.controls.unitaryValue.value ?? '0';
        const quantity = this.form.controls.quantity.value ?? '0';
        let result = +unitaryValue * +quantity;
        if (this.form.controls.solicitationType.value === 'Desconto') {
          result *= -1;
        }
        this.form.controls.value.patchValue(result.toString());
      }
    });

    this.form.controls.unitaryValue.valueChanges.subscribe(() => {
      if (!this.isOutrosCustos) {
        const unitaryValue = this.form.controls.unitaryValue.value ?? '0';
        const quantity = this.form.controls.quantity.value ?? '0';
        let result = +unitaryValue * +quantity;
        if (this.form.controls.solicitationType.value === 'Desconto') {
          result *= -1;
        }
        this.form.controls.value.patchValue(result.toString());
      }
    });
  }

  loadDataWithId() {
    forkJoin({
      opls: this.isAnalyst
        ? this.operatorService.getActiveOperators()
        : of([{}] as GetOperatorModel[]),
      units: this.isAnalyst
        ? this.unitService.getActiveUnits()
        : this.unitService.getUnitsByOpl(this.username),
      paymentTypes: this.solicitationService.getPaymentTypes(),
      solicitationTypes: this.solicitationService.getSolicitationTypes(),
      solicitation: this.solicitationService.getSolicitationById(
        +(this.id ?? '0'),
      ),
    }).subscribe({
      next: ({
        opls,
        units,
        paymentTypes,
        solicitationTypes,
        solicitation,
      }) => {
        if (this.isAnalyst) {
          this.handleGetActiveOperatorsSuccess(opls);
          this.handleGetActiveUnitsSuccess(units);
        } else {
          this.visible = !this.visible;
          this.form.controls.opl.patchValue(this.username);
          this.handleGetUnitsByOplSuccess(units);
        }
        this.handleGetPaymentTypesSuccess(paymentTypes);
        this.handleTypesListSuccess(solicitationTypes);
        this.handleGetSolicitationByIdSuccess(solicitation);
      },
      error: (error) => {
        this.opls = [];
        this.oplValues = [];
        this.units = [];
        this.unitValues = [];
        this.categories = [];
        this.categoryValues = [];
        this.subcategories = [];
        this.subCategoryValues = [];
        this.paymentTypes = [];
        this.paymentTypeValues = [];

        this.handleGetSolicitationByIdError(error as HttpErrorResponse);
      },
      complete: () => {
        this.isLoading = false;
        this.checkIfAllRequestsHaveBeenCompleted();
      },
    });
  }

  loadData() {
    forkJoin({
      opls: this.isAnalyst
        ? this.operatorService.getActiveOperators()
        : of([{}] as GetOperatorModel[]),
      units: this.isAnalyst
        ? this.unitService.getActiveUnits()
        : this.unitService.getUnitsByOpl(this.username),
      paymentTypes: this.solicitationService.getPaymentTypes(),
      solicitationTypes: this.solicitationService.getSolicitationTypes(),
    }).subscribe({
      next: ({
        opls, units, paymentTypes, solicitationTypes,
      }) => {
        if (this.isAnalyst) {
          this.handleGetActiveOperatorsSuccess(opls);
          this.handleGetActiveUnitsSuccess(units);
        } else {
          this.visible = !this.visible;
          this.form.controls.opl.patchValue(this.username);
          this.handleGetUnitsByOplSuccess(units);
        }
        this.handleGetPaymentTypesSuccess(paymentTypes);
        this.handleTypesListSuccess(solicitationTypes);
      },
      error: () => {
        this.opls = [];
        this.oplValues = [];
        this.units = [];
        this.unitValues = [];
        this.categories = [];
        this.categoryValues = [];
        this.subcategories = [];
        this.subCategoryValues = [];
        this.paymentTypes = [];
        this.paymentTypeValues = [];
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
        this.checkIfAllRequestsHaveBeenCompleted();
      },
    });
  }

  handleTypesListSuccess(res: GetSolicitationTypeModel[]) {
    this.types = res.map((x) => x.name);
    this.typeValues.push('Outros Custos');
    this.form.controls.solicitationType.patchValue('Outros Custos');
  }

  setRequiredJustify() {
    if (this.analystRole === 'Gerente Operação'
      || this.analystRole === 'Diretor Operação'
      || this.analystRole === 'Gerente Corporativo'
      || this.analystRole === 'Diretor Corporativo'
      || this.analystRole === 'Coordenador Suprimentos'
      || this.analystRole === 'Coordenador Corporativo'
    ) {
      this.isRequiredJustify = false;
    }
  }

  getLoggedUserData() {
    return this.storageService.getLoggedUserData()?.userRoleName;
  }

  checkIfAllRequestsHaveBeenCompleted() {
    if (!this.id && !this.isLoading) {
      this.loadFormFromStorage();
      this.alreadyLoadFromStorage = true;
    }
  }

  getUser() {
    this.username = this.storageService.getUser();
  }

  getUserRole() {
    this.role = this.storageService.getRole();
    if (this.role.name.includes('Operador')) {
      this.isAnalyst = false;
    } else {
      this.isAnalyst = true;
    }
  }

  handleGetSolicitationByIdSuccess(res: GetSolicitationModel): void {
    this.loading = false;
    this.solicitation = res;
    this.title = `Solicitação #${res.solicitationId
      .toString()
      .padStart(5, '0')}`;
    this.canValueReview = this.analystRole.toLocaleLowerCase() === 'supervisor'
      && res.solicitationStatusName.toLocaleLowerCase() === 'aguardando validação operacional';

    const ptBrDateFormat = 'DD/MM/YYYY HH:mm:ss';
    this.form.controls.data.patchValue(moment(res.openingDate).locale('pt-br').format(ptBrDateFormat));
    this.form.controls.status.patchValue(res.solicitationStatusName);
    this.form.controls.opl.patchValue(res.fantasyName);
    this.form.controls.unit.patchValue(res.unitName);
    this.form.controls.category.patchValue(res.categoryName);
    this.form.controls.subcategory.patchValue(res.subcategoryName);
    this.form.controls.paymentType.patchValue(res.paymentTypeName);
    this.form.controls.value.patchValue(res.value.toString());
    this.form.controls.negotiatedValue.patchValue(res.negotiatedValue.toString());
    this.form.controls.title.patchValue(res.title);
    this.form.controls.description.patchValue(res.description);
    this.form.controls.solicitationType.patchValue(res.solicitationTypeName);
    this.form.controls.quantity.patchValue(res.equipmentQuantity?.toString());
    this.form.controls.unitaryValue.patchValue(res.equipmentUnitaryValue?.toString());
    if (res.createByAnalyst !== null && res.createByAnalyst !== undefined) {
      this.form.controls.createBy.patchValue(res.createByAnalyst ?? '');
    } else {
      this.form.controls.createBy.patchValue(res.createByOperator ?? '');
    }

    this.recurrencySolicitation = res.recurrenceSolicitation;
    if (res.recurrenceSolicitation) {
      this.form.controls.recurrencyMonths.patchValue(res?.recurrenceMonths?.toString() ?? '1');
    } else {
      this.form.controls.recurrencyMonths.patchValue('1');
    }

    const uploadedFiles = res.attachments.map(
      (attachment) => ({
        fileName: attachment.fileName,
        uploaded: true,
        solicitationId: res.solicitationId,
        fileSize: attachment.fileSize,
      } as GetAttachmentModel),
    );

    this.selectedFiles = uploadedFiles;

    if (this.isAnalyst) {
      this.totalSize = uploadedFiles.reduce((acc, curr) => acc + curr.fileSize, 0);
    }

    this.oplValues.push(res.fantasyName);
    this.unitValues.push(res.unitName);
    this.categoryValues.push(res.categoryName);
    this.subCategoryValues.push(res.subcategoryName);
    this.paymentTypeValues.push(res.paymentTypeName);
    this.typeValues = [];
    this.typeValues.push(res.solicitationTypeName);

    this.rolePermission.quantity = false;
    this.rolePermission.unitaryValue = false;

    this.handleUnitsForUpdateSolicitation(this.unitsResponse);
    this.canEdit();
  }

  handleUnitsForUpdateSolicitation(res: GetUnitModel[]) {
    this.unitsResponse = res;
    const selectedUnit = this.unitsResponse.find(
      (x) => x.name === this.form.controls.unit.value ?? '',
    ) ?? new GetUnitModel();

    this.categories = selectedUnit.categories.map((y) => y.categoryName);

    const selectedCategory = this.unitsResponse
      .find((x) => x.name === this.form.controls.unit.value ?? '')
      ?.categories.find(
        (x) => x.categoryName === this.form.controls.category.value ?? '',
      ) ?? new GetUnitCategoryModel();

    this.subcategories = selectedCategory.subcategories ?? [];
    this.showSubcategory = selectedCategory.categoryName
      .toLowerCase()
      .startsWith('suprimento');
  }

  handleGetSolicitationByIdError(error: HttpErrorResponse) {
    if (error.status === 404) {
      this.authService.redirectToAccessDenied();
    } else {
      this.loading = false;
      const message = JSON.parse(JSON.stringify(error?.error));
      this.modalModel.buttonLabel = 'Entendi';
      this.modalModel.description = message?.Message ?? message;
      this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
      this.modalModel.title = 'Erro ao obter os dados';
      this.modalModel.success = true;
      this.modalModel.showModal = true;
    }
  }

  getOpls() {
    this.operatorService.getActiveOperators().subscribe({
      next: this.handleGetActiveOperatorsSuccess.bind(this),
      error: this.handleGetActiveOperatorsError.bind(this),
    });
  }

  handleGetActiveOperatorsSuccess(res: GetOperatorModel[]) {
    this.opls = res.map((x) => x.fantasyName);
  }

  handleGetActiveOperatorsError() {
    this.opls = [];
  }

  getUnits() {
    this.unitService.getActiveUnits().subscribe({
      next: this.handleGetActiveUnitsSuccess.bind(this),
      error: this.handleGetActiveUnitsError.bind(this),
    });
  }

  handleGetActiveUnitsSuccess(res: GetUnitModel[]) {
    this.unitsResponse = res;
    this.units = res.map((x) => x.name);
    this.categories = [];
    this.subcategories = [];
  }

  handleGetActiveUnitsError() {
    this.units = [];
    this.categories = [];
    this.subcategories = [];
  }

  getUnitsByOpl(operatorId: string) {
    this.unitService.getUnitsByOpl(operatorId).subscribe({
      next: this.handleGetUnitsByOplSuccess.bind(this),
      error: this.handleGetUnitsByOplError.bind(this),
    });
  }

  handleGetUnitsByOplSuccess(res: GetUnitModel[]) {
    this.unitsResponse = res;
    this.units = res.map((x) => x.name);
    this.fantasyName = res[0]?.categories[0]?.operatorName ?? '';
    this.categories = [];
    this.subcategories = [];
  }

  handleGetUnitsByOplError() {
    this.units = [];
    this.categories = [];
    this.subcategories = [];
  }

  getPaymentTypes() {
    this.solicitationService.getPaymentTypes().subscribe({
      next: this.handleGetPaymentTypesSuccess.bind(this),
      error: this.handleGetPaymentTypesError.bind(this),
    });
  }

  handleGetPaymentTypesSuccess(res: GetPaymentTypeModel[]) {
    this.paymentTypes = res.map((x) => x.name);
    if (!this.id) {
      this.paymentTypeValues.push(this.paymentTypes[0]);
    }
  }

  handleGetPaymentTypesError() {
    this.paymentTypes = [];
  }

  create() {
    if (this.sending) return;
    if (!this.checkFormIsValid()) return;

    const request = new CreateSolicitationModel();
    request.fantasyName = this.role.name.includes('Operador')
      ? this.fantasyName
      : this.form.controls.opl.value ?? '';
    request.unitName = this.form.controls.unit.value ?? '';
    request.categoryName = this.form.controls.category.value ?? '';
    request.subcategoryName = this.form.controls.subcategory.value ?? '';
    request.paymentTypeName = this.form.controls.paymentType.value ?? '';
    request.value = +(this.form.controls.value.value ?? 0);
    request.title = this.form.controls.title.value ?? '';
    request.description = this.form.controls.description.value ?? '';
    request.attachments = this.form.controls.attachments.value ?? [];
    request.solicitationTypeName = this.form.controls.solicitationType.value ?? '';
    request.equipmentUnitaryValue = +(
      this.form.controls.unitaryValue.value ?? 0
    );
    request.equipmentQuantity = +(this.form.controls.quantity.value ?? 0);
    request.recurrenceSolicitation = this.recurrencySolicitation;

    this.sending = true;
    this.enabledBack = false;
    this.solicitationService.createSolicitation(request).subscribe({
      next: this.handleCreateSolicitationSuccess.bind(this),
      error: this.handleCreateSolicitationError.bind(this),
    });
  }

  handleCreateSolicitationSuccess(solicitation: GetSolicitationModel) {
    if (this.fileInput.selectedFiles.some((x) => !x.uploaded)) {
      this.uploadInProgress = true;
      this.showProgressValue = true;
      this.fileInput
        .uploadFile(solicitation.solicitationId.toString())
        .subscribe(
          (event: HttpEvent<any>) => {
            let eventTotal;
            this.sending = false;
            this.uploadProgress = '30';
            switch (event.type) {
              case HttpEventType.UploadProgress:
                eventTotal = event.total ? event.total : 0;
                this.uploadProgress = (
                  30 + Math.round((event.loaded / eventTotal) * 50)
                ).toString();
                break;
              case HttpEventType.Response:
                this.uploadProgress = '100';
                this.showSuccessForCreateSolicitation();
                setTimeout(() => {
                  this.uploadProgress = '0';
                }, 1500);
                break;
              default:
                break;
            }
          },
          () => {
            this.showUploadFileError();
          },
        );
    } else {
      this.showSuccessForCreateSolicitation();
    }
    this.storageService.clearCreateSolicitationForm();
  }

  showSuccessForCreateSolicitation() {
    this.sending = false;
    this.enabledBack = true;
    this.modalModel.buttonLabel = 'Concluir';
    this.modalModel.description = 'Acompanhe o andamento através do menu Solicitações.';
    this.modalModel.imagePath = '../../../assets/icons/approve-modal.svg';
    this.modalModel.title = 'Sua solicitação foi enviada com sucesso!';
    this.modalModel.success = true;
    this.modalModel.showModal = true;
  }

  showUploadFileError() {
    this.sending = false;
    this.enabledBack = true;
    this.modalModel.buttonLabel = 'Concluir';
    this.modalModel.description = 'Sua solicitação foi enviada com sucesso! Porém ocorreu uma falha ao tentar enviar os arquivos, tente enviar os arquivos mais tarde ou entre em contato com algum administrador do sistema';
    this.modalModel.imagePath = '../../../assets/icons/approve-modal.svg';
    this.modalModel.title = 'Sua solicitação foi enviada com sucesso!';
    this.modalModel.success = true;
    this.modalModel.showModal = true;
  }

  handleCreateSolicitationError(res: HttpErrorResponse) {
    const message = JSON.parse(JSON.stringify(res.error));
    this.sending = false;
    this.enabledBack = true;
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.description = message?.Message;
    this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
    this.modalModel.title = 'Não foi possível enviar sua solicitação!';
    this.modalModel.success = false;
    this.modalModel.showModal = true;
  }

  approve(valueReview: boolean) {
    if (
      this.role.name.includes('Admin')
      || this.role.name.includes('Analista')
    ) {
      this.setRequiredJustify();
      this.modalTitle = 'Tem certeza que deseja aprovar?';
      this.buttonLabel = 'Aprovar';
      this.buttonImage = '../../../../assets/icons/approve-common-100.svg';
      this.buttonType = this.eButtonsType.primary;
      if (valueReview) {
        this.buttonColor = this.ButtonColorEnum.attention;
        this.sendApprovalValueReview = true;
        this.approval = true;
        this.sendApproval = false;
      } else {
        this.buttonColor = this.ButtonColorEnum.success;
        this.approval = true;
        this.sendApproval = true;
        this.sendApprovalValueReview = false;
      }
      this.showModal = true;
    }
  }

  disapprove() {
    if (
      this.role.name.includes('Admin')
      || this.role.name.includes('Analista')
    ) {
      this.modalTitle = 'Tem certeza que deseja reprovar?';
      this.buttonLabel = 'reprovar';
      this.isRequiredJustify = true;
      this.buttonImage = '../../../../assets/icons/disapprove-common-100.svg';
      this.buttonType = this.eButtonsType.delete;
      this.buttonColor = ButtonColorEnum.warning;
      this.approval = false;
      this.sendApproval = false;
      this.sendApprovalValueReview = false;
      this.showModal = true;
    }
  }

  modalClick(event: SolicitationJustify) {
    this.showModal = false;

    const { approved, disapproved, justification } = event;

    if (approved || disapproved) {
      this.update(approved, disapproved, justification ?? undefined);
    }
  }

  update(
    approved?: boolean,
    disapproved?: boolean,
    justification?: string | undefined,
  ) {
    if (this.sending) return;
    if (!this.checkFormIsValid()) return;

    this.sending = true;
    this.enabledBack = false;
    this.enabledApprove = this.sendApprovalValueReview ? false : !!approved;
    this.enabledDisapprove = !!disapproved;
    this.enabledValueReview = this.sendApprovalValueReview;
    const request = new UpdateSolicitationModel();
    request.recurrenceSolicitation = this.recurrencySolicitation;
    request.recurrenceMonths = this.recurrencySolicitation
      ? +(this.form.controls.recurrencyMonths.value ?? '0')
      : null;
    request.solicitationId = +(this.id ?? 0);

    if (approved) {
      request.categoryName = this.form.controls.category.value ?? '';
      request.subcategoryName = this.form.controls.subcategory.value ?? '';
      request.paymentTypeName = this.form.controls.paymentType.value ?? '';
      request.valueReview = this.sendApprovalValueReview;
      request.negotiatedValue = +(
        this.form.controls.negotiatedValue.value ?? 0
      );
      request.title = this.form.controls.title.value ?? '';
      request.description = this.form.controls.description.value ?? '';
      request.approved = true;
      request.justification = justification;
    } else if (disapproved) {
      request.approved = false;
      request.justification = justification;
    } else {
      request.unitName = this.form.controls.unit.value ?? '';
      request.categoryName = this.form.controls.category.value ?? '';
      request.subcategoryName = this.form.controls.subcategory.value ?? '';
      request.paymentTypeName = this.form.controls.paymentType.value ?? '';
      request.value = +(this.form.controls.value.value ?? 0);
      request.title = this.form.controls.title.value ?? '';
      request.description = this.form.controls.description.value ?? '';
    }

    if (this.fileInput.selectedFiles.some((x) => !x.uploaded)) {
      this.sending = false;
      this.uploadInProgress = true;
      this.showProgressValue = true;

      this.fileInput.uploadFile(this.id ?? '0').subscribe(
        (event: HttpEvent<any>) => {
          let eventTotal;
          this.sending = false;
          this.uploadProgress = '30';
          switch (event.type) {
            case HttpEventType.UploadProgress:
              eventTotal = event.total ? event.total : 0;
              this.uploadProgress = (
                30 + Math.round((event.loaded / eventTotal) * 50)
              ).toString();
              break;
            case HttpEventType.Response:
              this.uploadSuccess = true;
              this.uploadProgress = '100';
              this.solicitationService.updateSolicitation(request).subscribe({
                next: this.handleUpdateSolicitationSuccess.bind(this),
                error: this.handleUpdateSolicitationError.bind(this),
              });
              break;
            default:
              break;
          }
        },
        () => {
          this.uploadSuccess = false;
          this.solicitationService.updateSolicitation(request).subscribe({
            next: this.handleUpdateSolicitationSuccess.bind(this),
            error: this.handleUpdateSolicitationError.bind(this),
          });
        },
      );
    } else {
      this.uploadSuccess = true;
      this.solicitationService.updateSolicitation(request).subscribe({
        next: this.handleUpdateSolicitationSuccess.bind(this),
        error: this.handleUpdateSolicitationError.bind(this),
      });
    }
  }

  sendUpdate() {
    this.sending = true;
    this.enabledBack = false;
    const solicitation = new UpdateSolicitationModel();
    solicitation.solicitationId = this.solicitation.solicitationId;
    solicitation.categoryName = this.form.controls.category.value ?? '';
    solicitation.subcategoryName = this.form.controls.subcategory.value ?? '';
    solicitation.description = this.form.controls.description.value ?? '';
    solicitation.title = this.form.controls.title.value ?? '';
    solicitation.unitName = this.form.controls.unit.value ?? '';
    solicitation.value = parseInt(this.form.controls.value.value ?? '0', 10);
    solicitation.negotiatedValue = parseInt(
      this.form.controls.value.value ?? '0',
      10,
    );
    solicitation.paymentTypeName = this.form.controls.paymentType.value ?? '';

    if (this.fileInput.selectedFiles.some((x) => !x.uploaded)) {
      this.fileInput.uploadFile(this.id ?? '0').subscribe({
        next: () => {
          this.uploadSuccess = true;
          this.solicitationService.updateSolicitation(solicitation).subscribe({
            next: this.handleUpdateSolicitationSuccess.bind(this),
            error: this.handleUpdateSolicitationError.bind(this),
          });
        },
        error: () => {
          this.uploadSuccess = false;
          this.solicitationService.updateSolicitation(solicitation).subscribe({
            next: this.handleUpdateSolicitationSuccess.bind(this),
            error: this.handleUpdateSolicitationError.bind(this),
          });
        },
      });
    } else {
      this.uploadSuccess = true;
      this.solicitationService.updateSolicitation(solicitation).subscribe({
        next: this.handleUpdateSolicitationSuccess.bind(this),
        error: this.handleUpdateSolicitationError.bind(this),
      });
    }
    this.storageService.clearCreateSolicitationForm();
  }

  handleUpdateSolicitationSuccess() {
    this.uploadProgress = '100';
    this.showSuccessForUpdateSolicitation();
  }

  showSuccessForUpdateSolicitation() {
    this.sending = false;
    this.enabledBack = true;
    this.enabledApprove = true;
    this.enabledDisapprove = true;
    this.enabledValueReview = true;
    this.modalModel.buttonLabel = 'Concluir';
    if (this.uploadSuccess) {
      this.modalModel.description = 'Acompanhe o andamento através do menu Solicitações.';
    } else {
      this.modalModel.description = 'Sua solicitação foi enviada com sucesso! Porém ocorreu uma falha ao tentar enviar os arquivos, tente enviar os arquivos mais tarde ou entre em contato com algum administrador do sistema';
    }
    this.modalModel.imagePath = '../../../assets/icons/approve-modal.svg';
    this.modalModel.title = 'Sua solicitação foi atualizada com sucesso!';
    this.modalModel.success = true;
    this.modalModel.showModal = true;
  }

  handleUpdateSolicitationError(result: HttpErrorResponse) {
    const message = JSON.parse(JSON.stringify(result.error));
    this.sending = false;
    this.enabledBack = true;
    this.enabledApprove = true;
    this.enabledDisapprove = true;
    this.enabledValueReview = true;
    this.enabledBack = true;
    this.enabledApprove = true;
    this.enabledDisapprove = true;
    this.enabledValueReview = true;
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.description = message?.Message;
    this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
    this.modalModel.title = 'Não foi possível atualizar sua solicitação!';
    this.modalModel.success = false;
    this.modalModel.showModal = true;
    this.sendApproval = false;
    this.sendApprovalValueReview = false;
  }

  clickModal() {
    this.modalModel.showModal = false;

    if (this.modalModel.success) {
      this.navigateBack();
    }
  }

  navigateBack() {
    this.router.navigate(['solicitation']);
  }

  changeSelectedUnit(selected: string[]) {
    if (!Array.isArray(selected) || selected.length === 0) {
      this.unitValues = [];
      this.categories = [];
    } else {
      const selectedUnit = this.unitsResponse.find((x) => x.name === selected[0])
        ?? new GetUnitModel();
      this.unitValues = [selectedUnit.name];
      this.categories = selectedUnit.categories.map((y) => y.categoryName);
    }

    if (!this.alreadyLoadFromStorage) return;

    this.categoryValues = [];
    this.subcategories = [];
    this.subCategoryValues = [];
    this.showSubcategory = false;
  }

  changeSelectedCategory(selected: string[]) {
    if (
      !Array.isArray(selected)
      || selected.length === 0
      || this.unitValues.length === 0
    ) {
      this.categoryValues = [];
      this.subcategories = [];
      this.showSubcategory = false;
    } else {
      const selectedCategory = this.unitsResponse
        .find((x) => x.name === this.unitValues[0])
        ?.categories.find((x) => x.categoryName === selected[0])
        ?? new GetUnitCategoryModel();
      this.categoryValues = [selectedCategory.categoryName];
      this.subcategories = selectedCategory.subcategories ?? [];
      this.showSubcategory = selectedCategory.categoryName
        .toLowerCase()
        .startsWith('suprimento');
    }

    if (!this.alreadyLoadFromStorage) return;

    this.subCategoryValues = [];
  }

  loadFormFromStorage() {
    const solicitationForm = this.storageService.getCreateSolicitationForm();
    if (solicitationForm === undefined) return;

    this.form.controls.opl.patchValue(solicitationForm.fantasyName);
    this.form.controls.unit.patchValue(solicitationForm.unitName);
    this.form.controls.category.patchValue(solicitationForm.categoryName);
    this.form.controls.subcategory.patchValue(
      solicitationForm.subcategoryName ?? '',
    );
    this.form.controls.paymentType.patchValue(solicitationForm.paymentTypeName);
    this.form.controls.title.patchValue(solicitationForm.title);
    this.form.controls.description.patchValue(solicitationForm.description);

    this.oplValues = solicitationForm.fantasyName !== '' ? [solicitationForm.fantasyName] : [];
    this.unitValues = solicitationForm.unitName !== '' ? [solicitationForm.unitName] : [];
    this.categoryValues = solicitationForm.categoryName !== ''
      ? [solicitationForm.categoryName]
      : [];
    this.subCategoryValues = solicitationForm.subcategoryName !== ''
      ? [solicitationForm.subcategoryName]
      : [];
    this.paymentTypeValues = solicitationForm.paymentTypeName !== ''
      ? [solicitationForm.paymentTypeName]
      : [];

    if (this.unitValues?.length > 0) {
      this.changeSelectedUnit(this.unitValues);
    }

    if (this.categoryValues?.length > 0) {
      this.changeSelectedCategory(this.categoryValues);
    }
  }

  setFormValuesInStorage() {
    if (this.id || !this.alreadyLoadFromStorage) return;

    const solicitationForm = new CreateSolicitationModel();
    solicitationForm.fantasyName = this.role.name.includes('Operador')
      ? this.fantasyName
      : this.form.controls.opl.value ?? '';
    solicitationForm.unitName = solicitationForm.fantasyName === ''
      ? ''
      : this.form.controls.unit.value ?? '';
    solicitationForm.categoryName = this.form.controls.category.value ?? '';
    solicitationForm.subcategoryName = solicitationForm.unitName === ''
      ? ''
      : this.form.controls.subcategory.value ?? '';
    solicitationForm.paymentTypeName = this.form.controls.paymentType.value ?? '';
    solicitationForm.title = this.form.controls.title.value ?? '';
    solicitationForm.description = this.form.controls.description.value ?? '';
    solicitationForm.solicitationTypeName = this.form.controls.solicitationType.value ?? '';

    this.storageService.setCreateSolicitationForm(solicitationForm);
  }

  canEdit() {
    let result = false;
    this.approveButtons = false;
    this.sendButtons = false;

    if (this.role.name.includes('Operador')) {
      result = this.solicitation.solicitationStatusName.includes(
        SolicitationStatusEnum.AwaitingScreening,
      );
      this.showRecurrencyMonth = !result;
      this.sendButtons = result;
      this.approveButtons = false;
    } else if (this.analystRole === 'Triagem') {
      result = this.solicitation.solicitationStatusName.includes(
        SolicitationStatusEnum.AwaitingScreening,
      );
      this.approveButtons = result;
      this.sendButtons = result;
    } else if (this.analystRole === 'Analista Suprimentos') {
      result = this.solicitation.solicitationStatusName.includes(
        SolicitationStatusEnum.AwaitingSupplyValitation,
      )
        || this.solicitation.solicitationStatusName.includes(
          SolicitationStatusEnum.AwaitingScreening,
        )
        || this.solicitation.solicitationStatusName.includes(
          SolicitationStatusEnum.AwaitingValueReview,
        );
      this.approveButtons = result;
      this.sendButtons = result;
    } else if (this.analystRole === 'Analista Custos') {
      result = this.solicitation.solicitationStatusName.includes(
        SolicitationStatusEnum.AwaitingFinantialValidation,
      )
        || this.solicitation.solicitationStatusName.includes(
          SolicitationStatusEnum.AwaitingScreening,
        )
        || this.solicitation.solicitationStatusName.includes(
          SolicitationStatusEnum.AwaitingValueReview,
        )
        || ((this.solicitation.solicitationTypeName.includes('Custo Fixo')
          || this.solicitation.solicitationTypeName.includes('Desconto'))
          && this.solicitation.solicitationStatusName.includes(
            SolicitationStatusEnum.Closing,
          ));

      this.approveButtons = result
        || this.solicitation.solicitationStatusName.includes(
          SolicitationStatusEnum.Closing,
        );
      this.sendButtons = result
        || this.solicitation.solicitationStatusName.includes(
          SolicitationStatusEnum.Closing,
        );
    } else if (this.analystRole === 'Supervisor') {
      result = this.solicitation.solicitationStatusName.includes(
        SolicitationStatusEnum.AwaitingOperationalValidation,
      );
      this.approveButtons = result;
      this.sendButtons = result;
    } else if (this.analystRole === 'Gerente Operação') {
      result = this.solicitation.solicitationStatusName.includes(
        SolicitationStatusEnum.AwaitingOperationManager,
      );
      this.showRecurrencyMonth = !this.solicitation.solicitationStatusName.includes(
        SolicitationStatusEnum.AwaitingScreening,
      );
      this.approveButtons = result;
      this.sendButtons = result;
    } else if (this.analystRole === 'Diretor Operação') {
      result = this.solicitation.solicitationStatusName.includes(
        SolicitationStatusEnum.AwaitingOperationDirector,
      );
      this.showRecurrencyMonth = !this.solicitation.solicitationStatusName.includes(
        SolicitationStatusEnum.AwaitingScreening,
      );
      this.approveButtons = result;
      this.sendButtons = result;
    } else if (this.analystRole === 'Gerente Corporativo') {
      result = this.solicitation.solicitationStatusName.includes(
        SolicitationStatusEnum.AwaitingCorporateManager,
      );
      this.showRecurrencyMonth = !this.solicitation.solicitationStatusName.includes(
        SolicitationStatusEnum.AwaitingScreening,
      );
      this.approveButtons = result;
      this.sendButtons = result;
    } else if (this.analystRole === 'Diretor Corporativo') {
      result = this.solicitation.solicitationStatusName.includes(
        SolicitationStatusEnum.AwaitingCorporateDirector,
      );
      this.showRecurrencyMonth = !this.solicitation.solicitationStatusName.includes(
        SolicitationStatusEnum.AwaitingScreening,
      );
      this.approveButtons = result;
      this.sendButtons = result;
    } else if (this.analystRole === 'Coordenador Suprimentos') {
      result = this.solicitation.solicitationStatusName.includes(
        SolicitationStatusEnum.AwaitingSupplyCoordinator,
      );
      this.showRecurrencyMonth = !this.solicitation.solicitationStatusName.includes(
        SolicitationStatusEnum.AwaitingScreening,
      );
      this.approveButtons = result;
      this.sendButtons = result;
    } else if (this.analystRole === 'Coordenador Corporativo') {
      result = this.solicitation.solicitationStatusName.includes(
        SolicitationStatusEnum.AwaitingCorporateCoordinator,
      );
      this.showRecurrencyMonth = !this.solicitation.solicitationStatusName.includes(
        SolicitationStatusEnum.AwaitingScreening,
      );
      this.approveButtons = result;
      this.sendButtons = result;
    } else if (this.analystRole === 'Analista Pagamento') {
      result = false;
      this.approveButtons = result;
      this.sendButtons = result;
    }

    this.setPermission(result);
  }

  setPermission(canEdit: boolean) {
    if (!canEdit) {
      this.rolePermission.unity = false;
      this.rolePermission.category = false;
      this.rolePermission.subcategory = false;
      this.rolePermission.paymentType = false;
      this.rolePermission.negotiatedValue = false;
      this.rolePermission.title = false;
      this.rolePermission.description = false;
      this.rolePermission.date = false;
      this.rolePermission.opl = false;
      this.rolePermission.value = false;
      this.rolePermission.attachemnt = false;
      this.rolePermission.status = false;
      this.rolePermission.recurrencySolicitation = false;
      this.rolePermission.recurrencyMonths = false;
      return;
    }

    if (
      this.analystRole === 'Triagem'
      || this.analystRole === 'Analista Suprimentos'
      || this.analystRole === 'Analista Custos'
      || this.analystRole === 'Supervisor'
    ) {
      this.rolePermission.category = this.solicitation.solicitationStatusName === 'Aguardando Triagem'
        && this.analystRole !== 'Supervisor';
      this.rolePermission.subcategory = true;
      this.rolePermission.paymentType = true;
      this.rolePermission.negotiatedValue = true;
      this.rolePermission.title = true;
      this.rolePermission.description = true;
      this.rolePermission.attachemnt = true;
      this.rolePermission.unity = false;
      this.rolePermission.value = false;
      this.rolePermission.recurrencySolicitation = true;
      this.rolePermission.recurrencyMonths = true;
    } else if (
      this.role.name.includes('Operador')
      && this.solicitation?.solicitationStatusName
        === SolicitationStatusEnum.AwaitingScreening
    ) {
      this.rolePermission.unity = true;
      this.rolePermission.category = true;
      this.rolePermission.subcategory = true;
      this.rolePermission.paymentType = true;
      this.rolePermission.value = true;
      this.rolePermission.title = true;
      this.rolePermission.description = true;
      this.rolePermission.attachemnt = true;
      this.rolePermission.negotiatedValue = false;
      this.hide = true;
      this.rolePermission.recurrencySolicitation = true;
      this.rolePermission.recurrencyMonths = false;
      this.rolePermission.paymentType = false;
    } else {
      this.rolePermission.unity = false;
      this.rolePermission.category = false;
      this.rolePermission.subcategory = false;
      this.rolePermission.paymentType = false;
      this.rolePermission.negotiatedValue = false;
      this.rolePermission.title = false;
      this.rolePermission.description = false;
      this.rolePermission.date = false;
      this.rolePermission.opl = false;
      this.rolePermission.value = false;
      this.rolePermission.status = false;
      this.rolePermission.attachemnt = true;
      this.rolePermission.recurrencySolicitation = false;
      this.rolePermission.recurrencyMonths = false;
      this.rolePermission.paymentType = false;
    }
  }

  checkFormIsValid() {
    const valid = this.form.controls.opl.value
      && this.form.controls.unit.value
      && this.form.controls.category.value
      && ((this.showSubcategory && this.form.controls.subcategory.value)
        || !this.showSubcategory)
      && this.form.controls.paymentType.value
      && this.form.controls.value.value
      && this.form.controls.title.value;

    if (!valid) {
      this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
      this.modalModel.title = 'Dados obrigatorios não preenchidos';
      this.modalModel.description = 'Preencha todos os dados necessarios para continuar com a ação';
      this.modalModel.buttonLabel = 'Entendi';
      this.modalModel.success = false;
      this.modalModel.showModal = true;
      return false;
    }
    return true;
  }

  downloadAttatchment(fileName: string) {
    this.loadingAttach = true;
    this.solicitationService.downloadFile(this.id ?? '', fileName).subscribe({
      next: (event) => {
        if (event.type === HttpEventType.Response) {
          this.loadingAttach = false;
          const downloadedFile = new Blob([event?.body ?? ''], {
            type: event?.body?.type,
          });
          const a = document.createElement('a');
          a.setAttribute('style', 'display:none;');
          document.body.appendChild(a);
          a.download = fileName;
          a.href = URL.createObjectURL(downloadedFile);
          a.target = '_blank';
          a.click();
          document.body.removeChild(a);
        }
      },
      error: (error) => {
        this.loadingAttach = false;
        const message = JSON.parse(JSON.stringify(error?.status));
        switch (message) {
          case 404:
            this.authService.redirectToAccessDenied();
            break;
          case 400:
            this.modalModel.description = 'Você não possui permissão para baixar este arquivo.';
            break;
          default:
            this.modalModel.description = 'Tente novamente mais tarde ou entre em contato com o administrador do sistema.';
            break;
        }
        this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
        this.modalModel.title = 'Erro ao realizar o download do arquivo';
        this.modalModel.buttonLabel = 'Entendi';
        this.modalModel.success = false;
        this.modalModel.showModal = true;
      },
    });
  }

  handleCheckRecurencySolicitation(value: boolean) {
    this.recurrencySolicitation = value;
  }
}
