import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/common/services/base.service';
import { UpdateOperatorModel } from './models/update-operator-model';
import { GetCompanySizeModel } from './models/get-company-size-model';
import { GetEmployeeNumberModel } from './models/get-employee-number-model';
import { GetOperatorModel } from './models/get-operator-model';
import { VerifyOperatorModel } from './models/get-verify-operator-model';
import { GetCompanyGroupedPimsModel } from './models/get-company-grouped-pims.model';
import { CreateOperatorModel } from './models/create-operator-model';
import { PatchCompanyGroupedPimsModel } from './models/patch-company-grouped-pims.model';
import { GetCompanyGroupedPimsCnpjIdModel } from './models/get-company-pims-cnpj-id';
import { GetTransporterOperationModel } from './models/get-transporter-operation-mode';
import { CryptoService } from 'src/app/services/Encryption/CryptoService';

@Injectable({
  providedIn: 'root',
})
export class OperatorService extends BaseService {
  constructor(private http: HttpClient, private cryptoService: CryptoService) {
    super('operators');
  }

  getOperators() {
    return this.http.get<GetOperatorModel[]>(`${this.url}`);
  }

  getActiveOperators() {
    return this.http.get<GetOperatorModel[]>(`${this.url}/actives`);
  }

  getOperatorsPreRegisters() {
    return this.http.get<GetOperatorModel[]>(`${this.url}/pre-registers`);
  }

  getOperatorById(operatorId: string) {
    const operatorIdEncrypted = this.cryptoService.crypt(operatorId);
    return this.http.get<GetOperatorModel>(`${this.url}/${operatorIdEncrypted}`);
  }

  validateCnpjsPublic(cnpj: string) {
    return this.http.post<boolean>(`${this.publicUrl}/validators/cnpjs`, { value: cnpj } as VerifyOperatorModel);
  }

  validateCompanyNamesPublic(companyName: string) {
    return this.http.post<boolean>(`${this.publicUrl}/validators/company-names`, { value: companyName } as VerifyOperatorModel);
  }

  validateFantasyNamesPublic(fantasyName: string) {
    return this.http.post<boolean>(`${this.publicUrl}/validators/fantasy-names`, { value: fantasyName } as VerifyOperatorModel);
  }

  validateEmailsPublic(email: string) {
    return this.http.post<boolean>(`${this.publicUrl}/validators/emails`, { value: email } as VerifyOperatorModel);
  }

  createOperatorPublic(requestOperator: CreateOperatorModel, recaptcha: string) {
    const headers = new HttpHeaders({ recaptcha_token: recaptcha });
    return this.http.post<GetOperatorModel>(this.publicUrl, requestOperator, { headers: headers });
  }

  getEmployeeNumbers() {
    return this.http.get<GetEmployeeNumberModel[]>(`${this.url}/employee-numbers`);
  }

  getEmployeeNumbersPublic() {
    return this.http.get<GetEmployeeNumberModel[]>(`${this.publicUrl}/employee-numbers`);
  }

  getCompanySizes() {
    return this.http.get<GetCompanySizeModel[]>(`${this.url}/company-sizes`);
  }

  getCompanySizesPublic() {
    return this.http.get<GetCompanySizeModel[]>(`${this.publicUrl}/company-sizes`);
  }

  updateOperator(model: UpdateOperatorModel) {
    const operatorIdEncrypted = this.cryptoService.crypt(model.operatorId);
    return this.http.patch<UpdateOperatorModel>(`${this.url}/${operatorIdEncrypted}`, model);
  }

  updateOperatorApprove(cnpj: string) {
    const cnpjEncrypted = this.cryptoService.crypt(cnpj);
    return this.http.put<GetOperatorModel>(`${this.url}/${cnpjEncrypted}/approve`, cnpj);
  }

  updateOperatorDisapprove(cnpj: string) {
    const cnpjEncrypted = this.cryptoService.crypt(cnpj);
    return this.http.delete<any>(`${this.url}/${cnpjEncrypted}/disapprove`);
  }

  createOperatorPims(model: GetCompanyGroupedPimsModel) {
    const operatorIdEncrypted = this.cryptoService.crypt(model.operatorId);
    return this.http.post<GetCompanyGroupedPimsModel>(`${this.url}/${operatorIdEncrypted}/pims`, model);
  }

  updateOperatorPims(model: PatchCompanyGroupedPimsModel) {
    const operatorIdEncrypted = this.cryptoService.crypt(model.operatorId);
    return this.http.patch<PatchCompanyGroupedPimsModel>(`${this.url}/${operatorIdEncrypted}/pims/${model.operatorPims[0].transporterId}`, model);
  }

  deleteOperatorPims(cnpj: string, transporterId: string) {
    const cnpjEncrypted = this.cryptoService.crypt(cnpj);
    return this.http.delete<GetCompanyGroupedPimsModel>(`${this.url}/${cnpjEncrypted}/pims/${transporterId}`);
  }

  getCompaniesPims(cnpj: string) {
    const cnpjEncrypted = this.cryptoService.crypt(cnpj);
    return this.http.get<GetCompanyGroupedPimsModel>(`${this.url}/${cnpjEncrypted}/pims`);
  }

  getActiveCompaniesPims(cnpj: string) {
    const cnpjEncrypted = this.cryptoService.crypt(cnpj);
    return this.http.get<GetCompanyGroupedPimsModel>(`${this.url}/${cnpjEncrypted}/pims/actives`);
  }

  getCompanyPims(cnpj:string, transporterId: string) {
    const cnpjEncrypted = this.cryptoService.crypt(cnpj);
    return this.http.get<GetCompanyGroupedPimsCnpjIdModel>(`${this.url}/${cnpjEncrypted}/pims/${transporterId}`);
  }

  getTransporterOperations(cnpj:string, unitId:string, categoryId:string) {
    const cnpjEncrypted = this.cryptoService.crypt(cnpj);
    return this.http.get<GetTransporterOperationModel[]>(`${this.url}/${cnpjEncrypted}/pims/units/${unitId}/categories/${categoryId}/operations`);
  }
}
