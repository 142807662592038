import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Utils } from 'src/app/common/utils/utils';
import { CryptoService } from 'src/app/services/Encryption/CryptoService';
import { GetCompanyGroupedPimsModel } from 'src/app/services/operator/models/get-company-grouped-pims.model';
import { StatusColorEnum } from 'src/app/shared/enums/StatusColor';

@Component({
  selector: 'app-operator-pims-card',
  templateUrl: './operator-pims-card.component.html',
  styleUrls: ['./operator-pims-card.component.scss'],
})
export class OperatorPimsCardComponent {
  @Input() companiesPims: GetCompanyGroupedPimsModel = new GetCompanyGroupedPimsModel();

  public StatusColorEnum = StatusColorEnum;

  constructor(
    public util: Utils,
    public router: Router,
    private cryptoService: CryptoService,
  ) {}

  navigate(cnpj: string, transporterId: number) {
    var cnpjCrypto = this.cryptoService.crypt(cnpj);
    this.router.navigate([`admin/operator/${cnpjCrypto}/pims/${transporterId}`]);
  }
}
